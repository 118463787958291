import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StyledInner, ContentTextWrapper, Img } from './common.style';
import { H5Intro, Subtitle2, Text2 } from './common.styles.font';
import Video from './Video';
import { desktopViews, tabletViews } from '../utils/media';

const Wrapper = styled(StyledInner)`
    position: relative;
    display: grid;

    &.left {
        grid-template-columns: minmax(26rem, 40rem) minmax(6rem, 1fr) minmax(38rem, 5fr);
        grid-template-areas: 'nav . text';
    }

    &.right {
        grid-template-columns:
            minmax(38rem, 5fr)
            minmax(6rem, 1fr) minmax(26rem, 40rem);
        grid-template-areas: 'text . nav';
    }

    /* max-width: 820px */
    @media ${tabletViews.lg} {
        display: block;
    }
`;

const TitleWrapper = styled.div`
    grid-area: nav;
    width: 100%;
    height: fit-content;
    position: sticky;
    top: 8rem;
    left: 0;

    /* max-width: 820px */
    @media ${tabletViews.lg} {
        max-width: 100%;
        position: relative;
        top: 0;
        padding: 0;
    }
`;

const Nav = styled.ul`
    padding-left: 2rem;

    /* max-width: 992px */
    @media ${tabletViews.xlg} {
        padding-left: 2rem;
    }

    /* max-width: 820px */
    @media ${tabletViews.lg} {
        display: none;
    }
`;

const NavItem = styled.li`
    list-style: circle;
    padding: 0.6rem 0;
    text-align: left;

    a {
        font-family: 'Assistant', 'Roboto', 'open-sans';
        font-size: 1.9rem;
        letter-spacing: 0.4px;
        line-height: 1.5;
        font-weight: 500;
        color: var(--txt-inactive);
    }

    &.lang-pl a {
        font-family: 'Noto Sans', 'Roboto', 'open-sans';
        font-size: 1.7rem;
    }

    &:hover a {
        color: var(--txt-active);
    }

    &.active a {
        color: var(--secondary-500);
    }

    /* max-width: 1040px */
    @media ${desktopViews.xxs} {
        padding: 0.8rem 0;
    }
`;

const List = styled.ul`
    grid-area: text;
    width: 100%;

    /* max-width: 820px */
    @media ${tabletViews.lg} {
        max-width: 100%;
        min-width: 100%;
        min-height: auto;
        padding-left: 0;
    }
`;

export const ListItem = styled.li`
    position: relative;

    span {
        position: absolute;
        top: -5rem;
    }
`;

const DetailsWrapper = styled.div`
    margin-bottom: 4rem;

    .title {
        color: var(--subtitle-01);
        font-weight: 600;
        margin-bottom: 0.8rem;
    }

    p {
        font-weight: 400;
    }

    /* max-width: 820px */
    @media ${tabletViews.lg} {
        margin-bottom: 3rem;
    }
`;

const ImgVidWrapper = styled.div`
    margin-bottom: 1rem;
`;

interface Intro {
    id: string;
    txt: string;
}

interface Nav {
    id: string;
    idx: string;
    target: string;
    txt: string;
}

interface Content {
    id: string;
    idx: string;
    title: string;
    desc: string;
    img: any;
    alt: null | string;
    video: any;
    videoDesc: null | string;
}

interface Props {
    curLang: string;
    dataName: string;
    order: string;
    category: string;
    introArr: Intro[];
    navArr: Nav[];
    featureArr: Content[];
    openLightbox: (arg0: any) => void;
}

const FeatureContent: React.FC<Props> = ({ curLang, dataName, order, category, introArr, navArr, featureArr, openLightbox }) => {
    const { t } = useTranslation();
    const [activePoint, setActivePoint] = useState('');

    useEffect(() => {
        const points = document.querySelectorAll('.point');

        const options = {
            threshold: 0.8
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setActivePoint(entry.target.id);
                }
            });
        }, options);

        points.forEach((point) => {
            observer.observe(point);
        });

        return () => {
            points.forEach((point) => {
                observer.unobserve(point);
            });
        };
    }, []);

    return (
        <Wrapper id={`product-${dataName}__feature-${order}`} className={category}>
            <TitleWrapper className={category}>
                <ContentTextWrapper>
                    {introArr.map(({ id, txt }) => (
                        <H5Intro key={id} className={`lang-${curLang}`}>
                            {t(txt)}
                        </H5Intro>
                    ))}
                </ContentTextWrapper>

                <Nav>
                    {navArr.map(({ id, target, txt }) => (
                        <NavItem key={id} className={`${activePoint === target ? 'active' : ''} lang-${curLang}`}>
                            <a href={`#${target}`}>{t(txt)}</a>
                        </NavItem>
                    ))}
                </Nav>
            </TitleWrapper>

            <List>
                {featureArr.map(({ id, idx, title, desc, img, alt, video, videoDesc }) => (
                    <ListItem key={id}>
                        <span id={`product-${dataName}__feature-${idx}`} />
                        <DetailsWrapper id={id} className="point">
                            <Subtitle2 className={`lang-${curLang} title`}>{t(title)}</Subtitle2>

                            {img ? (
                                <ImgVidWrapper>{img && <Img src={img} alt={alt ? alt : ''} onClick={() => openLightbox(img)} />}</ImgVidWrapper>
                            ) : video ? (
                                <ImgVidWrapper>
                                    <Video videoSrc={video} videoDesc={videoDesc} />
                                </ImgVidWrapper>
                            ) : null}

                            <Text2 className={`lang-${curLang}`}>{t(desc)}</Text2>
                        </DetailsWrapper>
                    </ListItem>
                ))}
            </List>
        </Wrapper>
    );
};

export default FeatureContent;
