import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StyledWrapper } from '../../components/common.style';
import Head from '../../components/Head';

export const Wrapper = styled(StyledWrapper)`
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Inner = styled.div`
    position: relative;
    width: 100%;
    font-family: 'Roboto Mono', sans-serif;
    padding-bottom: 3rem;
    text-align: center;
    font-weight: 300;
    font-size: 4rem;
    letter-spacing: 10px;
    text-transform: uppercase;
    color: var(--txt-inactive);
`;

interface Props {
    appTitle: string;
    appDesc: string;
}

const ComingSoonPage: React.FC<Props> = ({ appTitle, appDesc }) => {
    const { t } = useTranslation();
    return (
        <>
            <Head title={appTitle} desc={appDesc} />
            <Wrapper className="bg-primary-gradient">
                <Inner>
                    <p>{t('common.txt.comingSoon')}</p>
                </Inner>
            </Wrapper>
        </>
    );
};

export default ComingSoonPage;
