import { useEffect } from "react";

const useClickOutside = (ref, handler) => {
  useEffect(
    () => {
      const listener = (e) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(e.target)) {
          return;
        }
        handler(e);
      };
      document.addEventListener("mousedown", listener);
      // document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        // document.removeEventListener("touchstart", listener);
      };
    },

    // Add ref and handler to effect dependencies
    [ref, handler]
  );
};

export default useClickOutside;
