import { v4 as uuidv4 } from 'uuid';
// Images
import HeroEn from '../assets/products/secureFolderSharing/imgs/hero_en.png';
import HeroDe from '../assets/products/secureFolderSharing/imgs/hero_de.png';
import IntroEn from '../assets/products/secureFolderSharing/imgs/intro_en.svg';
import IntroDe from '../assets/products/secureFolderSharing/imgs/intro_de.svg';
import TechEn from '../assets/products/secureFolderSharing/imgs/tech_en.svg';
import TechDe from '../assets/products/secureFolderSharing/imgs/tech_de.svg';
// Videos
import ViewPortalZoom from '../assets/products/secureFolderSharing/vids/SFS_View_Portal_Zoom.mov';
import SendEmail from '../assets/products/secureFolderSharing/vids/SFS_Send_Email.mov';
import ReceivedEmail from '../assets/products/secureFolderSharing/vids/SFS_Email_Received.mov';

export const SFSProductDetails = {
    appTitle: 'product.SFS.helmet.title',
    appDesc: 'product.SFS.helmet.desc',
    dataName: 'SFS',
    productName: 'Secure Folder Sharing',
    heroDetails: {
        title: 'product.SFS.main.title',
        subtitle: 'product.SFS.main.subtitle',
        desc: 'product.SFS.main.desc',
        img: {
            en: [HeroEn, HeroEn],
            de: [HeroDe, HeroDe],
            pl: [HeroEn, HeroEn],
            zh: [HeroEn, HeroEn],
        },
        firstBtnTxt: 'common.btn.learnMore',
        firstBtnAction: () => {
            const targetEl = document.getElementById('product-SFS__intro');
            targetEl?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        },
        secondBtnTxt: null,
        secondBtnAction: null,
    },
    introWideVideo: null,
    intro: {
        textAlign: 'right',
        desc: ['product.SFS.intro.desc'],
        video: null,
        videoDesc: null,
        img: { en: IntroEn, de: IntroDe, pl: IntroEn, zh: IntroEn },
        alt: null,
        isBtn: true,
        btnTxt: 'common.btn.viewMoreVideos',
        btnAction: () => window.open('https://vimeo.com/user219937106', '_blank'),
    },
    overview: null,
    explanationDetails: null,
    moreVideos: null,
    vidDetails: null,
    requirement: {
        textAlign: 'right',
        intro: 'product.SFS.requirement.intro',
        pointArr: [
            {
                id: uuidv4(),
                txt: 'product.SFS.requirement.point1',
            },
            {
                id: uuidv4(),
                txt: 'product.SFS.requirement.point2',
            },
        ],
        img: { en: TechEn, de: TechDe, pl: TechEn, zh: TechEn },
        btnTxt: 'common.btn.viewMoreVideos',
        isReady: true,
        btnAction: () => window.open('https://vimeo.com/user219937106', '_blank'),
    },
    pricingSection: {
        intro: 'product.SFS.pricing.intro',
        sideIntro: 'product.SFS.pricing.sideIntro',
        pricingArr: [
            {
                id: uuidv4(),
                idx: 1,
                category: 'basic',
                title: 'common.pricing.busineseBasic',
                price: {
                    title: 'product.SFS.pricing.basicPrice',
                    unit: 'product.SFS.pricing.basicPriceUnit',
                    method: 'product.SFS.pricing.basicPriceMethod',
                },
                customPrice: null,
                featuresArr: [
                    {
                        id: uuidv4(),
                        txt: 'product.SFS.pricing.basicPoint1',
                    },
                    {
                        id: uuidv4(),
                        txt: 'product.SFS.pricing.basicPoint2',
                    },
                    {
                        id: uuidv4(),
                        txt: 'product.SFS.pricing.basicPoint3',
                    },
                    {
                        id: uuidv4(),
                        txt: 'product.SFS.pricing.basicPoint4',
                    },
                ],
                isFreeTrialBtn: false,
            },
            {
                id: uuidv4(),
                idx: 2,
                category: 'enterprise',
                title: 'common.pricing.busineseEnterprise',
                price: null,
                customPrice: 'product.SFS.pricing.enterprisePrice',
                featuresArr: [
                    {
                        id: uuidv4(),
                        txt: 'product.SFS.pricing.enterprisePoint1',
                    },
                    {
                        id: uuidv4(),
                        txt: 'product.SFS.pricing.enterprisePoint2',
                    },
                    {
                        id: uuidv4(),
                        txt: 'product.SFS.pricing.enterprisePoint3',
                    },
                    {
                        id: uuidv4(),
                        txt: 'product.SFS.pricing.enterprisePoint4',
                    },
                ],
                isFreeTrialBtn: false,
            },
        ],
    },
    requestSection: {
        product: 'Secure Folder Sharing',
        services: null,
        isReady: false,
        btnTxt: 'common.btn.viewMoreVideos',
        btnAction: (curLang) => window.open('https://vimeo.com/user219937106', '_blank'),
    },
};

export const SFSFeatureDetails = {
    moreBtnSection: null,
    intro: {
        section1: [
            {
                id: uuidv4(),
                txt: 'product.SFS.feature.intro',
            },
        ],
    },
    nav: {
        section1: [
            {
                id: uuidv4(),
                idx: '1',
                target: 'automated-secure-document-sharing-with-SharePoint',
                txt: 'product.SFS.feature.point1Title',
            },
            {
                id: uuidv4(),
                idx: '2',
                target: 'custom-mailing-module',
                txt: 'product.SFS.feature.point2Title',
            },
            {
                id: uuidv4(),
                idx: '3',
                target: 'branded-email-notifications',
                txt: 'product.SFS.feature.point3Title',
            },
        ],
    },
    content: {
        section1: [
            {
                id: 'automated-secure-document-sharing-with-SharePoint',
                idx: '1',
                title: 'product.SFS.feature.point1Title',
                desc: 'product.SFS.feature.point1Desc',
                img: null,
                alt: null,
                video: ViewPortalZoom,
                videoDesc: 'SFS_View SharePoint Portal',
            },
            {
                id: 'custom-mailing-module',
                idx: '2',
                title: 'product.SFS.feature.point2Title',
                desc: 'product.SFS.feature.point2Desc',
                img: null,
                alt: null,
                video: SendEmail,
                videoDesc: 'SFS_Send Email',
            },
            {
                id: 'branded-email-notifications',
                idx: '3',
                title: 'product.SFS.feature.point3Title',
                desc: 'product.SFS.feature.point3Desc',
                img: null,
                alt: null,
                video: ReceivedEmail,
                videoDesc: 'SFS_Received Email',
            },
        ],
    },
};
