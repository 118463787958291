import React from 'react';
import { useTranslation } from 'react-i18next';
import { UilArrowUpRight } from '@iconscout/react-unicons';
import styled from 'styled-components';
import { Img, StyledInner, StyledWrapperStandard } from '../../components/common.style';
import { Text1, Subtitle1 } from '../../components/common.styles.font';
import Video from '../../components/Video';
import Button from '../../components/buttons/Button';
import { tabletViews } from '../../utils/media';

export const Wrapper = styled(StyledWrapperStandard)``;

export const ContentWrapper = styled(StyledInner)`
    display: grid;

    &.text-right {
        grid-template-columns:
            minmax(38rem, 5fr)
            minmax(6rem, 1fr) minmax(26rem, 40rem);
        grid-template-areas: 'vidImg . content';
    }

    &.text-left {
        grid-template-columns:
            minmax(26rem, 40rem)
            minmax(6rem, 1fr) minmax(38rem, 5fr);
        grid-template-areas: 'content . vidImg';
    }

    align-items: center;

    /* max-width: 820px */
    @media ${tabletViews.lg} {
        &.text-right,
        &.text-left {
            grid-template-columns: 1fr;
            grid-template-areas:
                'vidImg'
                'content';
            row-gap: 4rem;
        }
    }
`;

const TextBtnWrapper = styled.div`
    grid-area: content;
`;

const TextWrapper = styled.div`
    margin-bottom: 3rem;

    p {
        margin-bottom: 3rem;
    }

    .question {
        margin-bottom: 0;
    }

    /* max-width: 820px */
    @media ${tabletViews.lg} {
        p {
            margin-bottom: 2.4rem;
        }
    }
`;

const VidImgWrapper = styled.div`
    grid-area: vidImg;
    width: 100%;

    img {
        width: 100%;
    }
`;

interface Image {
    [key: string]: any;
}

interface Props {
    curLang: string;
    dataName: string;
    section: {
        textAlign: string;
        desc: string[];
        video: any;
        videoDesc: string | null;
        img: Image | null;
        alt: string | null;
        isBtn: boolean;
        btnTxt: string | null;
        btnAction: (arg0?: string) => void | null;
    };
    openLightbox: (arg0: any) => void;
}

const ProductIntroSection: React.FC<Props> = ({ curLang, dataName, section, openLightbox }) => {
    const { t } = useTranslation();
    return (
        <Wrapper id={`product-${dataName}__intro`} className="bg-tertiary">
            <ContentWrapper className={`text-${section.textAlign}`}>
                <VidImgWrapper>
                    {section.video && <Video videoSrc={section.video} isAutoPlay={true} status={'active'} />}
                    {section.img && (
                        <Img
                            src={section.img[curLang]}
                            alt={section.alt ? section.alt : ''}
                            onClick={() => section.img && openLightbox(section.img[curLang])}
                        />
                    )}
                </VidImgWrapper>

                <TextBtnWrapper>
                    <TextWrapper>
                        {section.desc.map((txt, idx) =>
                            idx === section.desc.length - 1 ? (
                                <Subtitle1 key={`${txt}_${idx}`} className={`lang-${curLang} question`}>
                                    {t(txt)}
                                </Subtitle1>
                            ) : (
                                <Text1 key={`${txt}_${idx}`} className={`lang-${curLang}`}>
                                    {t(txt)}
                                </Text1>
                            ),
                        )}
                    </TextWrapper>

                    {section.isBtn && (
                        <Button
                            fs="16"
                            width="fit-content"
                            appearance="primary-outline"
                            txt={section.btnTxt && t(section.btnTxt)}
                            icon={<UilArrowUpRight className="icon icon-arrow-up" />}
                            handleClick={section.btnAction}
                        />
                    )}
                </TextBtnWrapper>
            </ContentWrapper>
        </Wrapper>
    );
};

export default ProductIntroSection;
