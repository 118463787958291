import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StyledWrapperStandard, StyledInner, TitleWrapper, Img } from '../../components/common.style';
import { H4Title, Text1 } from '../../components/common.styles.font';
import { tabletViews } from '../../utils/media';

const Wrapper = styled(StyledWrapperStandard)``;

const ContentWrapper = styled(StyledInner)`
    position: relative;
    display: grid;

    &.text-right {
        grid-template-columns:
            minmax(26rem, 40rem)
            minmax(6rem, 1fr) minmax(38rem, 5fr);
        grid-template-areas: 'text  . img';
    }

    &.text-left {
        grid-template-columns:
            minmax(38rem, 5fr)
            minmax(6rem, 1fr) minmax(26rem, 40rem);
        grid-template-areas: 'img . text';
    }

    align-items: center;
    margin: 0 auto;

    /* max-width: 820px */
    @media ${tabletViews.lg} {
        &.text-right,
        &.text-left {
            grid-template-columns: 1fr;
            grid-template-areas:
                'img'
                'text';
            row-gap: 4rem;
        }
    }
`;

const TextWrapper = styled.div`
    grid-area: text;

    p {
        margin-bottom: 3rem;
    }

    p:last-of-type {
        margin-bottom: 0;
    }

    /* max-width: 820px */
    @media ${tabletViews.lg} {
        p {
            margin-bottom: 2.4rem;
        }
    }
`;

const ImgWrapper = styled.div`
    position: relative;
    grid-area: img;
    width: 100%;
`;

interface Content {
    id: string;
    txt: string;
}

interface Props {
    curLang: string;
    dataName: string;
    section: {
        textAlign: string;
        content: Content[];
        img: any;
    };
    openLightbox: (arg0: any) => void;
}

const ProductExplanationSection: React.FC<Props> = ({ curLang, dataName, section, openLightbox }) => {
    const { t } = useTranslation();

    return (
        <Wrapper className="bg-secondary">
            <TitleWrapper className="title-card-wrapper">
                <H4Title className="title-span-secondary">
                    <span>{t('common.work.title')}</span>
                </H4Title>
            </TitleWrapper>

            <ContentWrapper className={`text-${section.textAlign}`}>
                <ImgWrapper>
                    <Img
                        id={`product-${dataName}__img-explnation`}
                        src={section.img}
                        alt="product-explanation"
                        onClick={() => openLightbox(section.img)}
                    />
                </ImgWrapper>

                <TextWrapper className="left">
                    {section?.content.map(({ id, txt }) => (
                        <Text1 key={id} className={`lang-${curLang}`}>
                            {t(txt)}
                        </Text1>
                    ))}
                </TextWrapper>
            </ContentWrapper>
        </Wrapper>
    );
};

export default ProductExplanationSection;
