import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UilArrowUpRight } from '@iconscout/react-unicons';
import { StyledInner, StyledWrapper } from '../../components/common.style';
import ArrowContact from '../../assets/icons/arrow-contact.svg';
import { H4Title, Text1, Text2 } from '../../components/common.styles.font';
import { desktopViews, tabletViews } from '../../utils/media';
import { contactLink, blubinaLink } from '../../utils/links';

const Wrapper = styled(StyledWrapper)`
    position: relative;
    width: 100%;
`;

const Inner = styled(StyledInner)`
    position: relative;
    margin: 0 auto;
    display: grid;
    grid-template-columns:
        minmax(22rem, 40rem) minmax(14rem, 18rem)
        minmax(50rem, 70rem);
    grid-template-areas: 'title btn main';

    /* max-width: 992px */
    @media ${tabletViews.xlg} {
        margin: initial;
        grid-template-columns: minmax(6rem, 8rem) minmax(50rem, 1fr);
        grid-template-areas:
            'btn title'
            'btn main';
    }

    /* max-width: 768px */
    @media ${tabletViews.sm} {
        grid-template-columns: 1fr;
        grid-template-areas:
            'title'
            'main';
    }
`;

const TitleWrapper = styled.div`
    grid-area: title;
    justify-self: end;
    text-align: right;

    /* max-width: 1040px */
    @media ${desktopViews.xxs} {
        justify-self: start;
        text-align: left;
        margin-bottom: 3rem;
    }

    /* max-width: 768px */
    @media ${tabletViews.sm} {
        /* padding-top: 4rem; */
    }
`;

const ContactBtn = styled.div`
    grid-area: btn;
    position: absolute;
    top: -24rem;
    justify-self: center;

    span {
        position: absolute;
        color: var(--txt-inactive);
        font-weight: 500;
        font-size: 1.5rem;
        text-transform: uppercase;
        top: -0.5rem;
        left: 2.5rem;
        width: max-content;
    }

    /* max-width: 1200px */
    @media ${desktopViews.xs} {
        top: -18rem;
    }

    /* max-width: 992px */
    @media ${tabletViews.xlg} {
        top: -16rem;
        justify-self: start;
    }

    /* max-width: 768px */
    @media ${tabletViews.sm} {
        display: none;
    }
`;

const Main = styled.div`
    grid-area: main;
`;

const Details = styled.div`
    position: relative;
    padding: 3.6rem 0;
    line-height: 1.7;

    .company-name {
        font-weight: 600;
        color: var(--txt-active);
    }

    &:not(:first-of-type)::before {
        content: '';
        background: var(--txt-inactive);
        width: 6rem;
        height: 1px;
        position: absolute;
        top: 0;
        left: 0;
    }

    a {
        display: block;
    }

    a:active,
    a:visited {
        color: var(--txt-inactive);
    }

    /* max-width: 1040px */
    @media ${desktopViews.xxs} {
        padding: 3.2rem 0;
    }
`;

export const Link = styled.a`
    display: flex;
    align-items: center;
    font-weight: 400;
    gap: 4rem;
    color: var(--link-txt);

    .icon {
        width: 1.8rem;
        transform: translateY(3px);
        margin-left: 1rem;
    }

    &:hover {
        color: var(--link-txt-h) !important;
    }

    &:active {
        color: var(--link-txt-f) !important;
    }
`;

interface Props {
    curLang: string;
}

const ContactSection: React.FC<Props> = ({ curLang }) => {
    const { t } = useTranslation();
    return (
        <Wrapper id="main__contact" className="bg-primary-gradient-revert">
            <Inner>
                <TitleWrapper>
                    <H4Title className="title-span-secondary" data-aos-delay="200" data-aos="fade-up">
                        <span>{t('main.contactImprint.title')}</span>
                    </H4Title>
                </TitleWrapper>

                <ContactBtn>
                    <img src={ArrowContact} alt="arrow-contact" />
                    {/* <span>{t('common.btn.contactUs')}</span> */}
                </ContactBtn>

                <Main data-aos-delay="200" data-aos="fade-up">
                    <Details>
                        <Text1 className={`lang-${curLang} company-name`}>Blubina GmbH</Text1>
                        <Text2 className={`lang-${curLang}`}>Aschauer Str. 21, 81549 Munich, Germany</Text2>
                    </Details>
                    <Details>
                        <Text2 className={`lang-${curLang}`}>
                            <Link href="tel:4989125012460">
                                +49 89 12 50 12 46-0
                                <UilArrowUpRight className="icon icon-link" />
                            </Link>
                        </Text2>
                        <Text2 className={`lang-${curLang}`}>
                            <Link href={`mailto:${contactLink}`}>
                                {contactLink}
                                <UilArrowUpRight className="icon icon-link" />
                            </Link>
                        </Text2>
                        <Text2 className={`lang-${curLang}`}>
                            <Link href={blubinaLink} target="_blank" rel="noreferrer">
                                {blubinaLink}
                                <UilArrowUpRight className="icon icon-link" />
                            </Link>
                        </Text2>
                    </Details>
                    <Details>
                        <Text2 className={`lang-${curLang}`}>{t('main.contactImprint.taxNumber')}DE259391841</Text2>
                        <Text2 className={`lang-${curLang}`}>
                            {t('main.contactImprint.handels')}Amtsgericht München, HRB 173386
                        </Text2>
                        <Text2 className={`lang-${curLang}`}>{t('main.team.position1')}: Manfred Vaillancourt</Text2>
                    </Details>
                </Main>
            </Inner>
        </Wrapper>
    );
};

export default ContactSection;
