import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StyledBtn } from './common.style';
import { tabletViews } from '../utils/media';
import IMG from '../assets/teams-headshot.png';
import IMGS from '../assets/teams-headshot-s.png';
import Arrow from '../assets/icons/arrow.svg';
import { Subtitle2, Text2 } from './common.styles.font';

const ProfileName = styled.div`
    grid-area: pName;
    font-size: 3.6rem;

    h3 {
        font-weight: 500;
        line-height: 1.2;
    }

    p {
        font-weight: 300;
        color: var(--txt-inactive);
    }

    /* max-width: 1536px */
    @media (max-width: 1536px) {
        font-size: 3.6rem;
    }

    /* max-width: 992px */
    @media ${tabletViews.xlg} {
        font-size: calc(22px + 0.390625vw);
    }
`;

const Profile = styled.div`
    grid-area: pDetails;
    display: flex;
    align-items: flex-start;
`;

const ProfileImg = styled.img`
    grid-area: pImg;
    width: 13rem;

    /* max-width: 1536px */
    @media (max-width: 1536px) {
        width: 12rem;
    }

    /* max-width: 992px */
    @media ${tabletViews.xlg} {
        width: 10rem;
    }

    /* max-width: 600px */
    @media ${tabletViews.xs} {
        display: none;
    }
`;

const ProfileImgSm = styled.img`
    display: none;
    width: 8rem;

    /* max-width: 600px */
    @media ${tabletViews.xs} {
        display: block;
    }
`;

const ProfileDetails = styled.div`
    width: 100%;
`;

const Details = styled.div`
    margin-bottom: 4rem;

    .subtitle {
        margin-bottom: 1.2rem;
    }
`;

const ContactBtn = styled(StyledBtn)`
    display: flex;
    align-items: center;

    img {
        position: relative;
        z-index: 1;
        transform: scale(0.9);
    }

    &:hover .contact-circle,
    &:active .contact-circle {
        background: var(--btn-contact-h);
    }

    &:hover .contact-circle::before {
        transform: scale(1.4);
    }

    &:hover .contact-circle::after {
        transform: scale(1.8);
    }

    &:active .contact-circle::before,
    &:active .contact-circle::after {
        transform: scale(1);
    }
`;

const CircleContact = styled.div`
    position: relative;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    background: var(--btn-contact);
    display: grid;
    place-items: center;
    margin-right: 1.2rem;
    z-index: 2;

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    &::before {
        transition: transform 0.2s;
    }
`;

const teamDetails = {
    point1: {
        title: 'main.team.detailTitle1',
        list: [
            'main.team.MBA',
            'main.team.accountant',
            'main.team.accountantInternational',
            'main.team.banker',
        ],
    },
    point2: {
        title: 'main.team.detailTitle2',
        list: [
            'main.team.cs',
            'main.team.microsoftIT',
            'main.team.microsoftSQL',
            'main.team.crystalReport',
        ],
    },
};

interface Props {
    curLang: string;
}

const Members: React.FC<Props> = ({ curLang }) => {
    const { t } = useTranslation();

    return (
        <>
            <ProfileName
                className="team-profile-name"
                data-aos="fade-up"
                data-aos-delay="160"
            >
                <h3>Manfred Vaillancourt</h3>
                <p>{t('main.team.position1')}</p>
            </ProfileName>

            <Profile>
                <ProfileImgSm src={IMGS} alt="team-headshot" />
                <ProfileImg
                    className="team-profile-img"
                    src={IMG}
                    alt="team-headshot"
                />
                <ProfileDetails
                    className="team-profile-details"
                    data-aos="fade-up"
                    data-aos-delay="160"
                >
                    <Details>
                        <Text2 className={`lang-${curLang}`}>
                            {t('main.team.detailTxt')}
                        </Text2>
                    </Details>

                    <Details>
                        <Subtitle2 className={`lang-${curLang} subtitle`}>
                            {t(teamDetails.point1.title)}
                        </Subtitle2>

                        <div>
                            {teamDetails.point1.list.map((item) => (
                                <Text2 key={item} className={`lang-${curLang}`}>
                                    - {t(item)}
                                </Text2>
                            ))}
                        </div>
                    </Details>

                    <Details>
                        <Subtitle2 className={`lang-${curLang} subtitle`}>
                            {t(teamDetails.point2.title)}
                        </Subtitle2>

                        <div>
                            {teamDetails.point2.list.map((item) => (
                                <Text2 key={item} className={`lang-${curLang}`}>
                                    - {t(item)}
                                </Text2>
                            ))}
                        </div>
                    </Details>

                    <ContactBtn
                        onClick={() => {
                            const targetEl =
                                document.getElementById('main__contact');
                            if (targetEl) {
                                targetEl.scrollIntoView();
                            }
                        }}
                    >
                        <CircleContact className="contact-circle">
                            <img src={Arrow} alt="contact-btn" />
                        </CircleContact>
                        <span>{t('common.btn.contactUs')}</span>
                    </ContactBtn>
                </ProfileDetails>
            </Profile>
        </>
    );
};

export default Members;
