import { v4 as uuidv4 } from 'uuid';
import { UilLabelAlt, UilRefresh, UilLocationArrow, UilAlignAlt, UilWindow } from '@iconscout/react-unicons';
// Images
import HeroEn from '../assets/products/orgCharts/imgs/hero_en.png';
import HeroDe from '../assets/products/orgCharts/imgs/hero_de.png';

// Videos

export const orgChartsProductDetails = {
    appTitle: 'product.orgCharts.helmet.title',
    appDesc: 'product.orgCharts.helmet.desc',
    dataName: 'orgCharts',
    productName: 'Blubina Org-Charts',
    heroDetails: {
        title: 'product.orgCharts.main.title',
        subtitle: 'product.orgCharts.main.subtitle',
        desc: 'product.orgCharts.main.desc',
        img: {
            en: [HeroEn, HeroEn],
            de: [HeroDe, HeroDe],
            pl: [HeroEn, HeroEn],
            zh: [HeroEn, HeroEn],
        },
        firstBtnTxt: 'common.btn.learnMore',
        firstBtnAction: () => {
            const targetEl = document.getElementById('product-orgCharts__overview');
            targetEl?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        },
        secondBtnTxt: null,
        secondBtnAction: null,
    },
    introWideVideo: null,
    intro: null,
    overview: [
        {
            id: uuidv4(),
            icon: <UilLabelAlt className="icon icon-label" />,
            name: 'Display of shareholding amount',
            txt: 'product.orgCharts.overview.point1',
        },
        {
            id: uuidv4(),
            icon: <UilRefresh className="icon icon-refresh" />,
            name: 'Automatically generated',
            txt: 'product.orgCharts.overview.point2',
        },
        {
            id: uuidv4(),
            icon: <UilLocationArrow className="icon icon-locate" />,
            name: 'Interactive filtering',
            txt: 'product.orgCharts.overview.point3',
        },
        {
            id: uuidv4(),
            icon: <UilAlignAlt className="icon icon-align" />,
            name: 'Customized adjustments',
            txt: 'product.orgCharts.overview.point4',
        },
        {
            id: uuidv4(),
            icon: <UilWindow className="icon icon-window" />,
            name: 'Modern web display with extras',
            txt: 'product.orgCharts.overview.point5',
        },
    ],
    explanationDetails: null,
    moreVideos: null,
    vidDetails: null,
    // requirement: {
    //     textAlign: 'right',
    //     intro: 'product.orgCharts.requirement.intro',
    //     pointArr: [
    //         {
    //             id: uuidv4(),
    //             txt: 'product.orgCharts.requirement.point1',
    //         },
    //         {
    //             id: uuidv4(),
    //             txt: 'product.orgCharts.requirement.point2',
    //         },
    //     ],
    //     img: null,
    //     btnTxt: 'common.txt.comingSoon',
    //     isReady: false,
    //     btnAction: null,
    // },
    requirement: null,
    pricingSection: {
        intro: 'product.orgCharts.pricing.intro',
        sideIntro: 'product.orgCharts.pricing.sideIntro',
        pricingArr: [
            {
                id: uuidv4(),
                idx: 1,
                category: 'basic',
                title: 'common.pricing.busineseBasic',
                price: {
                    title: 'product.orgCharts.pricing.basicPrice',
                    unit: 'product.orgCharts.pricing.basicPriceUnit',
                    method: 'product.orgCharts.pricing.basicPriceMethod',
                },
                customPrice: null,
                featuresArr: [
                    {
                        id: uuidv4(),
                        txt: 'product.orgCharts.pricing.basicPoint1',
                    },
                    {
                        id: uuidv4(),
                        txt: 'product.orgCharts.pricing.basicPoint2',
                    },
                    {
                        id: uuidv4(),
                        txt: 'product.orgCharts.pricing.basicPoint3',
                    },
                ],
                isFreeTrialBtn: false,
            },
            {
                id: uuidv4(),
                idx: 2,
                category: 'enterprise',
                title: 'common.pricing.busineseEnterprise',
                price: null,
                customPrice: 'product.orgCharts.pricing.enterprisePrice',
                featuresArr: [
                    {
                        id: uuidv4(),
                        txt: 'product.orgCharts.pricing.enterprisePoint1',
                    },
                    {
                        id: uuidv4(),
                        txt: 'product.orgCharts.pricing.enterprisePoint2',
                    },
                    {
                        id: uuidv4(),
                        txt: 'product.orgCharts.pricing.enterprisePoint3',
                    },
                    {
                        id: uuidv4(),
                        txt: 'product.orgCharts.pricing.enterprisePoint4',
                    },
                ],
                isFreeTrialBtn: false,
            },
        ],
    },
    requestSection: {
        product: 'Blubina Org-Charts',
        services: null,
        isReady: false,
        btnTxt: 'common.txt.comingSoon',
        btnAction: null,
    },
};

export const orgChartsFeatureDetails = {
    moreBtnSection: null,
    intro: {
        section1: [
            {
                id: uuidv4(),
                txt: 'product.orgCharts.feature.intro',
            },
        ],
    },
    nav: {
        section1: [
            {
                id: uuidv4(),
                idx: '1',
                target: 'loads-and-auto-displays-hierarchies-and-role-from-Blubina-Core',
                txt: 'product.orgCharts.feature.point1Title',
            },
            {
                id: uuidv4(),
                idx: '2',
                target: 'dynamic-filtering-for-specific-persons-or-relations',
                txt: 'product.orgCharts.feature.point2Title',
            },
            {
                id: uuidv4(),
                idx: '3',
                target: 'interactive-adjustments-saved-in-Blubina-Core',
                txt: 'product.orgCharts.feature.point3Title',
            },
            {
                id: uuidv4(),
                idx: '4',
                target: 'automatic-update',
                txt: 'product.orgCharts.feature.point4Title',
            },
        ],
    },
    content: {
        section1: [
            {
                id: 'loads-and-auto-displays-hierarchies-and-role-from-Blubina-Core',
                idx: '1',
                title: 'product.orgCharts.feature.point1Title',
                desc: 'product.orgCharts.feature.point1Desc',
                img: null,
                alt: null,
                video: null,
                videoDesc: null,
            },
            {
                id: 'dynamic-filtering-for-specific-persons-or-relations',
                idx: '2',
                title: 'product.orgCharts.feature.point2Title',
                desc: 'product.orgCharts.feature.point2Desc',
                img: null,
                alt: null,
                video: null,
                videoDesc: null,
            },
            {
                id: 'interactive-adjustments-saved-in-Blubina-Core',
                idx: '3',
                title: 'product.orgCharts.feature.point3Title',
                desc: 'product.orgCharts.feature.point3Desc',
                img: null,
                alt: null,
                video: null,
                videoDesc: null,
            },
            {
                id: 'automatic-update',
                idx: '4',
                title: 'product.orgCharts.feature.point4Title',
                desc: 'product.orgCharts.feature.point4Desc',
                img: null,
                alt: null,
                video: null,
                videoDesc: null,
            },
        ],
    },
};
