import { v4 as uuidv4 } from 'uuid';
import { contactLink } from '../utils/links'; // Images
import HeroEn from '../assets/services/hero_en.png';
import HeroDe from '../assets/services/hero_de.png';

export const servicesDetails = {
    appTitle: 'services.helmet.title',
    appDesc: 'services.helmet.desc',
    dataName: 'services',
    productName: 'Services',
    heroDetails: {
        title: 'services.main.title',
        subtitle: null,
        desc: 'services.main.desc',
        img: {
            en: [HeroEn, HeroEn],
            de: [HeroDe, HeroDe],
            pl: [HeroEn, HeroEn],
            zh: [HeroEn, HeroEn]
        },
        firstBtnTxt: 'common.btn.learnMore',
        firstBtnAction: () => {
            const targetEl = document.getElementById('services__overview');
            targetEl?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        },
        secondBtnTxt: 'common.btn.contactUs',
        secondBtnAction: () => (window.location.href = `mailto:${contactLink}`)
    },
    overview: [
        {
            id: uuidv4(),
            title: 'services.software.title',
            intro: 'services.software.intro',
            pointList: [
                {
                    id: uuidv4(),
                    txt: 'services.software.point1'
                },
                {
                    id: uuidv4(),
                    txt: 'services.software.point2'
                },
                {
                    id: uuidv4(),
                    txt: 'services.software.point3'
                },
                {
                    id: uuidv4(),
                    txt: 'services.software.point4'
                },
                {
                    id: uuidv4(),
                    txt: 'services.software.point5'
                },
                {
                    id: uuidv4(),
                    txt: 'services.software.point6'
                }
            ]
        },
        {
            id: uuidv4(),
            title: 'services.profession.title',
            intro: 'services.profession.intro',
            pointList: [
                {
                    id: uuidv4(),
                    txt: 'services.profession.point1'
                },
                {
                    id: uuidv4(),
                    txt: 'services.profession.point2'
                },
                {
                    id: uuidv4(),
                    txt: 'services.profession.point3'
                },
                {
                    id: uuidv4(),
                    txt: 'services.profession.point4'
                },
                {
                    id: uuidv4(),
                    txt: 'services.profession.point5'
                },
                {
                    id: uuidv4(),
                    txt: 'services.profession.point6'
                }
            ]
        },
        {
            id: uuidv4(),
            title: 'services.technology.title',
            intro: 'services.technology.intro',
            pointList: [
                {
                    id: uuidv4(),
                    txt: 'services.technology.point1'
                },
                {
                    id: uuidv4(),
                    txt: 'services.technology.point2'
                },
                {
                    id: uuidv4(),
                    txt: 'services.technology.point3'
                },
                {
                    id: uuidv4(),
                    txt: 'services.technology.point4'
                },
                {
                    id: uuidv4(),
                    txt: 'services.technology.point5'
                },
                {
                    id: uuidv4(),
                    txt: 'services.technology.point6'
                },
                {
                    id: uuidv4(),
                    txt: 'services.technology.point7'
                },
                {
                    id: uuidv4(),
                    txt: 'services.technology.point8'
                }
            ]
        }
    ],
    requestSection: {
        product: null,
        services: 'services.request.text',
        isReady: true,
        btnTxt: 'common.btn.contactUs',
        btnAction: () => (window.location.href = `mailto:${contactLink}`)
    }
};
