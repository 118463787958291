import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledQuoteInner, StyledWrapperStandard } from './common.style';
import { H2Title } from './common.styles.font';
import { isInViewport } from '../helpers/scrollIntoView';

interface Props {
    curLang: string;
}

const SolutionIntro: React.FC<Props> = ({ curLang }) => {
    const { t } = useTranslation();

    useEffect(() => {
        const scrollText = () => {
            const introEl = document.getElementById('solutions-intro');
            const introInnerEl = document.getElementById('main__solution-intro-inner');
            const bg = document.getElementById('main__bg-whole');

            if (isInViewport(introEl)) {
                introInnerEl?.classList.add('active');
                bg?.classList.add('active');
            } else {
                introInnerEl?.classList.remove('active');
                bg?.classList.remove('active');
            }
        };

        window.addEventListener('scroll', () => scrollText());
        return window.removeEventListener('scroll', scrollText);
    }, []);

    return (
        <StyledWrapperStandard id="solutions-intro" className="bg-primary">
            <StyledQuoteInner id="main__solution-intro-inner">
                <H2Title className={`lang-${curLang}`}>{t('main.solutions.intro')}</H2Title>
            </StyledQuoteInner>
        </StyledWrapperStandard>
    );
};

export default SolutionIntro;
