import { v4 as uuidv4 } from 'uuid';
// Images
import PDFSplitterHeroImg from '../assets/products/pdfSplitter/imgs/hero.png';
import PDFSplitterExplanationImg from '../assets/products/pdfSplitter/imgs/explanation.png';
// Videos
import VidMultiRecipients from '../assets/products/pdfSplitter/vids/Blubina_PDF_Splitter_230613_multiple recipients.mp4';
import VidSplitPDF from '../assets/products/pdfSplitter/vids/Blubina_PDF_Splitter_230529_PDF Splitter.mp4';
import { contactLink } from '../utils/links';

export const pdfSplitterProductDetails = {
    appTitle: 'product.pdfSplitter.helmet.title',
    appDesc: 'product.pdfSplitter.helmet.desc',
    dataName: 'pdfSplitter',
    productName: 'PDF Splitter',
    heroDetails: {
        title: 'product.pdfSplitter.main.title',
        subtitle: 'product.pdfSplitter.main.subtitle',
        desc: 'product.pdfSplitter.main.desc',
        img: {
            en: [PDFSplitterHeroImg, PDFSplitterHeroImg],
            de: [PDFSplitterHeroImg, PDFSplitterHeroImg],
            pl: [PDFSplitterHeroImg, PDFSplitterHeroImg],
            zh: [PDFSplitterHeroImg, PDFSplitterHeroImg],
        },
        firstBtnTxt: 'common.btn.learnMore',
        firstBtnAction: () => {
            const targetEl = document.getElementById('product-pdfSplitter__intro');
            targetEl?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        },
        secondBtnTxt: 'common.btn.requestDemo',
        secondBtnAction: () => (window.location = `mailto:${contactLink}`),
    },
    introWideVideo: null,
    intro: {
        textAlign: 'right',
        desc: ['product.pdfSplitter.intro.desc1', 'product.pdfSplitter.intro.desc2'],
        video: VidMultiRecipients,
        videoDesc: 'multiple recipients',
        img: null,
        alt: null,
        isBtn: false,
        btnTxt: null,
        btnAction: null,
    },
    overview: null,
    explanationDetails: {
        textAlign: 'left',
        content: [
            {
                id: uuidv4(),
                txt: 'product.pdfSplitter.work.desc1',
            },
            {
                id: uuidv4(),
                txt: 'product.pdfSplitter.work.desc2',
            },
        ],
        img: PDFSplitterExplanationImg,
    },
    moreVideos: null,
    vidDetails: null,
    requirement: null,
    pricingSection: {
        intro: 'product.pdfSplitter.pricing.intro',
        sideIntro: 'product.pdfSplitter.pricing.sideIntro',
        pricingArr: [
            {
                id: uuidv4(),
                idx: 1,
                category: 'basic',
                title: 'common.pricing.busineseBasic',
                price: {
                    title: 'product.pdfSplitter.pricing.basicPrice',
                    unit: 'product.pdfSplitter.pricing.basicPriceUnit',
                    method: 'product.pdfSplitter.pricing.basicPriceMethod',
                },
                customPrice: null,
                featuresArr: [
                    {
                        id: uuidv4(),
                        txt: 'product.pdfSplitter.pricing.basicPoint1',
                    },
                    {
                        id: uuidv4(),
                        txt: 'product.pdfSplitter.pricing.basicPoint2',
                    },
                    {
                        id: uuidv4(),
                        txt: 'product.pdfSplitter.pricing.basicPoint3',
                    },
                ],
                isFreeTrialBtn: false,
            },
            {
                id: uuidv4(),
                idx: 2,
                category: 'enterprise',
                title: 'common.pricing.busineseEnterprise',
                price: null,
                customPrice: 'product.pdfSplitter.pricing.enterprisePrice',
                featuresArr: [
                    {
                        id: uuidv4(),
                        txt: 'product.pdfSplitter.pricing.enterprisePoint1',
                    },
                    {
                        id: uuidv4(),
                        txt: 'product.pdfSplitter.pricing.enterprisePoint2',
                    },
                    {
                        id: uuidv4(),
                        txt: 'product.pdfSplitter.pricing.enterprisePoint3',
                    },
                    {
                        id: uuidv4(),
                        txt: 'product.pdfSplitter.pricing.enterprisePoint4',
                    },
                ],
                isFreeTrialBtn: false,
            },
        ],
    },
    requestSection: {
        product: 'PDF Splitter',
        services: null,
        isReady: true,
        btnTxt: 'common.btn.requestDemo',
        btnAction: () => (window.location = `mailto:${contactLink}`),
    },
};

export const pdfSplitterFeatureDetails = {
    moreBtnSection: null,
    intro: {
        section1: [
            {
                id: uuidv4(),
                txt: 'product.pdfSplitter.feature.intro1',
            },
            {
                id: uuidv4(),
                txt: 'product.pdfSplitter.feature.intro2',
            },
        ],
    },
    nav: {
        section1: [
            {
                id: uuidv4(),
                idx: '1',
                target: 'feature_split-up-large-PDF-files-into-single-documents',
                txt: 'product.pdfSplitter.feature.point1Title',
            },
            {
                id: uuidv4(),
                idx: '2',
                target: 'feature_stand-alone-or-database-supported-and-integrated-into-individual-workflows',
                txt: 'product.pdfSplitter.feature.point2Title',
            },
        ],
    },
    content: {
        section1: [
            {
                id: 'feature_split-up-large-PDF-files-into-single-documents',
                idx: '1',
                title: 'product.pdfSplitter.feature.point1Title',
                desc: 'product.pdfSplitter.feature.point1Desc',
                img: null,
                alt: null,
                video: VidSplitPDF,
                videoDesc: 'pdf-splitter_seperate-large-PDF-file',
            },
            {
                id: 'feature_stand-alone-or-database-supported-and-integrated-into-individual-workflows',
                idx: '2',
                title: 'product.pdfSplitter.feature.point1Title',
                desc: 'product.pdfSplitter.feature.point1Desc',
                img: null,
                alt: null,
                video: null,
                videoDesc: null,
            },
        ],
    },
};
