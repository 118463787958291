import React from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { BtnTooltip } from './Tooltip';

export const Wrapper = styled.div`
    position: relative;

    .tooltip {
        top: 5rem;
    }

    &:hover .tooltip {
        visibility: visible;
        opacity: 1;
    }
`;

export const OptionBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.8rem;
    height: 3.6rem;
    background: var(--btn-action-bg);
    color: var(--btn-action-txt);
    border: 1px solid var(--btn-action-bd);
    background: var(--btn-action-bg);
    border-radius: var(--tag-bd-r);

    .icon {
        width: 2rem;
    }

    .icon-lookup {
        width: 1.8rem;
    }

    &:hover {
        color: var(--btn-action-txt-h);
        border: 1px solid var(--btn-action-bd-h);
        background: var(--btn-action-bg-h);
    }

    &:focus,
    &:active,
    &.active {
        border: 1px solid var(--btn-action-bd-f);
        color: var(--btn-action-txt-f);
        background: var(--btn-action-bg-f);
    }
`;

interface Props {
    idx: number;
    txt: string;
    activeIdx: number;
    setActiveIdx: React.Dispatch<React.SetStateAction<number>>;
    icon: any;
}

const FeatureActionBtn: React.FC<Props> = ({
    idx,
    txt,
    activeIdx,
    setActiveIdx,
    icon,
}) => {
    const { t } = useTranslation();

    const handleActionClick = (targetIdx: number) => {
        setActiveIdx(targetIdx);
    };

    return (
        <Wrapper>
            <OptionBtn
                className={`btn-static ${idx === activeIdx ? 'active' : ''}`}
                onClick={() => handleActionClick(idx)}
            >
                {icon}
            </OptionBtn>
            <BtnTooltip className="tooltip">{t(txt)}</BtnTooltip>
        </Wrapper>
    );
};

export default FeatureActionBtn;
