import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StyledWrapperStandard, StyledInner, TitleWrapper, IntroWrapper } from '../../components/common.style';
import PricingCard from '../../components/PricingCard';
import { H4Title, H5Intro, Text1 } from '../../components/common.styles.font';
import { desktopViews, tabletViews } from '../../utils/media';

const CardIntroWrapper = styled.div`
    /* max-width: 1200px */
    @media ${desktopViews.xs} {
        display: none;
    }
`;

const CardsWrapper = styled(StyledInner)`
    display: grid;
    align-items: stretch;
    justify-content: space-around;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    column-gap: 3rem;
    row-gap: 4rem;

    /* max-width: 768px */
    @media ${tabletViews.sm} {
        grid-template-columns: 1fr;
    }
`;

interface Feature {
    id: string;
    txt: string;
}

interface Card {
    id: string;
    idx: number;
    category: string;
    title: string;
    price: {
        title: string;
        unit: string;
        method: string;
    };
    customPrice: null | string;
    featuresArr: Feature[];
    isFreeTrialBtn: boolean;
}

interface Props {
    curLang: string;
    section: {
        intro: string;
        sideIntro: string;
        pricingArr: Card[];
    };
}

const ProductPricingSection: React.FC<Props> = ({ curLang, section }) => {
    const { t } = useTranslation();

    return (
        <StyledWrapperStandard className="bg-tertiary">
            <TitleWrapper className="title-intro-wrapper">
                <H4Title className="title-span-tertiary">
                    <span>{t('common.pricing.title')}</span>
                </H4Title>
            </TitleWrapper>
            <IntroWrapper>
                <Text1 className={`lang-${curLang}`}>
                    <Trans i18nKey={t(section.intro)} />
                </Text1>
            </IntroWrapper>
            <CardsWrapper>
                <CardIntroWrapper>
                    <H5Intro className={`lang-${curLang} intro-thin`}>{t(section.sideIntro)}</H5Intro>
                </CardIntroWrapper>
                {section?.pricingArr.map(({ id, category, title, price, customPrice, featuresArr, isFreeTrialBtn }) => <PricingCard key={id} curLang={curLang} category={category} title={title} price={price} customPrice={customPrice} featuresArr={featuresArr} isFreeTrialBtn={isFreeTrialBtn} />)}
            </CardsWrapper>
        </StyledWrapperStandard>
    );
};

export default ProductPricingSection;
