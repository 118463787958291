import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import PrivacyPolicyPage from './pages/privacyPolicy/PrivacyPolicyPage';
import RootPage from './pages/root/RootPage';
import MainPage from './pages/main/MainPage';
import ProductPage from './pages/product/ProductPage';
import { BlubinaCoreFeatureDetails, BlubinaCoreProductDetails } from './data/blubinaCoreProductDetails';
import { pdfSplitterFeatureDetails, pdfSplitterProductDetails } from './data/pdfSplitterDetails';
import { getLanguageFromURL } from './helpers/getLangFromURL';
import { SFSFeatureDetails, SFSProductDetails } from './data/SFSProductDetails';
import { orgChartsFeatureDetails, orgChartsProductDetails } from './data/orgChartsProductDetails';
import ComingSoonPage from './pages/comingSoon/ComingSoonPage';
import ServicesPage from './pages/services/ServicesPage';
import { servicesDetails } from './data/servicesDetails';

const App = () => {
    const { t } = useTranslation();
    const [curLang, setCurLang] = useState<string>('en');
    const [curView, setCurView] = useState<string | null>(null);
    const location = useLocation();
    const navigate = useNavigate();
    const urlLang = getLanguageFromURL();

    // first render get localstorage language
    useEffect(() => {
        const getSavedLanguage = () => {
            const savedLang = localStorage.getItem('lang');
            const defaultLang = 'de';
            const parsedLang = savedLang ? JSON.parse(savedLang) : defaultLang;

            updateLanguage(parsedLang);
            navigate(`/${parsedLang}`);
        };

        const updateLanguage = (lang: any) => {
            i18n.changeLanguage(lang);
            setCurLang(lang);
            localStorage.setItem('lang', JSON.stringify(lang));
        };

        const redirectIfNecessary = (newLang: any) => {
            const pathParts = location.pathname.split('/');
            if (pathParts[1] !== newLang) {
                pathParts[1] = newLang;
                navigate(pathParts.join('/'));
            }
        };

        if (location.pathname === '/') {
            getSavedLanguage();
            return;
        }

        if (location.pathname.includes('privacy-policy')) {
            if (urlLang === 'en' || urlLang === 'de' || urlLang === 'pl') {
                updateLanguage(urlLang);
            } else {
                updateLanguage('de');
                redirectIfNecessary('de');
            }
        } else {
            if (urlLang === 'en' || urlLang === 'de' || urlLang === 'pl' || urlLang === 'zh') {
                updateLanguage(urlLang);
            } else {
                updateLanguage('de');
                redirectIfNecessary('de');
            }
        }
    }, [curLang, location.pathname]);

    useEffect(() => {
        const locationArr = location.pathname.split('/');
        const isProductPage = locationArr.includes('product');
        setCurView(isProductPage ? 'product' : 'homepage');
        // window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <Routes>
            {/* Main / Privacy Policy Page */}
            <Route path="/:lang/privacy-policy" element={<PrivacyPolicyPage appTitle={t('privacyPolicy.helmet.title')} appDesc={t('privacyPolicy.helmet.desc')} curLang={curLang} setCurLang={setCurLang} />} />

            {/* Main */}
            <Route path="/" element={<RootPage curView={curView} curLang={curLang} setCurLang={setCurLang} />}>
                <Route path=":lang" element={<MainPage appTitle={t('main.helmet.title')} appDesc={t('main.helmet.desc')} curView={curView} curLang={curLang} />} />

                {/* Services */}
                <Route path=":lang/services" element={<ServicesPage curLang={curLang} productDetailsObj={servicesDetails} />} />

                {/* Product / Blubina Core */}
                <Route path=":lang/product/Blubina-Core" element={<ProductPage curLang={curLang} productDetailsObj={BlubinaCoreProductDetails} productFeatureSection={BlubinaCoreFeatureDetails} />} />

                {/* Product / PDF Splitter */}
                <Route path=":lang/product/pdf-splitter" element={<ProductPage curLang={curLang} productDetailsObj={pdfSplitterProductDetails} productFeatureSection={pdfSplitterFeatureDetails} />} />

                {/* Product / Secure Folder Sharing */}
                <Route path=":lang/product/secure-folder-sharing" element={<ProductPage curLang={curLang} productDetailsObj={SFSProductDetails} productFeatureSection={SFSFeatureDetails} />} />

                {/* Product / Blubina Org-Charts */}
                <Route path=":lang/product/blubina-org-charts" element={<ProductPage curLang={curLang} productDetailsObj={orgChartsProductDetails} productFeatureSection={orgChartsFeatureDetails} />} />

                {/* Product / Secure Folder Sharing  */}
                <Route path=":lang/product/secure-folder-sharing" element={<ComingSoonPage appTitle={t('product.SFS.helmet.title')} appDesc={t('product.SFS.helmet.desc')} />} />

                {/* Product / Blubina Org-Charts  */}
                <Route path=":lang/product/blubina-org-charts" element={<ComingSoonPage appTitle={t('product.orgCharts.helmet.title')} appDesc={t('product.orgCharts.helmet.desc')} />} />
            </Route>
        </Routes>
    );
};

export default App;
