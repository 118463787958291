import React from "react";
import styled, { keyframes } from "styled-components";
import { desktopViews, mobileViews, tabletViews } from "../utils/media";

const rotate = keyframes`
  from {
    transform: rotateX(0) rotateY(0);
  } to {
    transform: rotateX(360deg) rotateY(360deg);
  }
`;

const Wrapper = styled.div`
  justify-self: end;
  width: 25rem;
  height: 25rem;
  display: grid;
  /* border: 1px solid red; */

  /* max-width: 1440px */
  @media ${desktopViews.lg} {
    justify-self: center;
  }

  /* max-width: 1200px */
  @media ${desktopViews.xs} {
    transform: scale(0.8);
  }

  /* max-width: 992px */
  @media ${tabletViews.xlg} {
    padding-left: 10rem;
  }

  /* max-width: 768px */
  @media ${tabletViews.sm} {
    transform: scale(0.7);
  }

  /* max-width: 480px */
  @media ${mobileViews.xlg} {
    transform: scale(0.6);
    padding-left: 6rem;
  }
`;

const Innter = styled.div`
  grid-area: img;
  perspective: 800px;
`;

const CubeSides = styled.div`
  height: 15rem;
  width: 15rem;
  position: relative;
  transform-style: preserve-3d;
  transform-origin: center;
  transform: rotateX(0) rotateY(0);
  animation: ${rotate} 15s linear infinite;

  .side {
    position: absolute;
    height: inherit;
    width: inherit;
    opacity: 0.7;
  }

  .side:first-child {
    background-color: var(--cube-side-1);
    transform: translateZ(75px);
  }

  .side:nth-child(2) {
    background-color: var(--cube-side-2);
    transform: rotateX(90deg) translateZ(75px);
  }

  .side:nth-child(3) {
    background-color: var(--cube-side-3);
    transform: rotateX(-90deg) translateZ(75px);
  }

  .side:nth-child(4) {
    background-color: var(--cube-side-4);
    transform: rotateY(90deg) translateZ(75px);
  }

  .side:nth-child(5) {
    background-color: var(--cube-side-5);
    transform: rotateY(-90deg) translateZ(75px);
  }

  .side:last-child {
    background-color: var(--cube-side-6);
    transform: translateZ(-75px);
  }
`;

const Cube = () => {
  return (
    <Wrapper data-aos="fade-up" data-aos-delay="400">
      <Innter>
        <CubeSides>
          <div className="side"></div>
          <div className="side"></div>
          <div className="side"></div>
          <div className="side"></div>
          <div className="side"></div>
          <div className="side"></div>
        </CubeSides>
      </Innter>
    </Wrapper>
  );
};

export default Cube;
