const navHeightObj = {
    hero: 0,
    challenges: 15,
    solutions: 30,
    products: 53,
    team: 68,
    partners: 88,
    contact: 100,
};

export const calculateSectionDistance = (elementName, pos, extra) => {
    let section = document.getElementById(elementName);
    let position = section?.offsetTop + section?.offsetHeight - extra;
    let distance = position - pos;
    return distance;
};

export const mainScrollHandler = () => {
    let fillHeight;
    // let activeNavName;
    let activeNavIdx;
    let pos = window.scrollY + 65;

    let distanceHero = calculateSectionDistance('main__hero', pos, 0);
    let distanceChallenge = calculateSectionDistance(
        'main__challenges',
        pos,
        300,
    );
    let distanceSolution = calculateSectionDistance(
        'main__solutions',
        pos,
        200,
    );
    let distanceProduct = calculateSectionDistance('main__products', pos, 200);
    let distanceTeam = calculateSectionDistance('main__team', pos, 200);
    let distancePartner = calculateSectionDistance('main__partners', pos, 400);
    let distanceContact = calculateSectionDistance('main__contact', pos, 400);

    let min = Math.min(
        ...[
            distanceHero,
            distanceChallenge,
            distanceSolution,
            distanceProduct,
            distanceTeam,
            distancePartner,
            distanceContact,
        ].filter((num) => num > 0),
    );

    switch (min) {
        case distanceHero:
            fillHeight = `${navHeightObj['hero']}%`;
            // activeNavName = 'hero';
            activeNavIdx = 0;
            break;

        case distanceChallenge:
            // 1
            fillHeight = `${navHeightObj['challenges']}%`;
            // activeNavName = 'challenges';
            activeNavIdx = 1;
            break;

        case distanceSolution:
            // 1.5
            fillHeight = `${navHeightObj['solutions']}%`;
            // activeNavName = 'solutions';
            activeNavIdx = 2;
            break;

        case distanceProduct:
            // 1
            fillHeight = `${navHeightObj['products']}%`;
            // activeNavName = 'products';
            activeNavIdx = 3;
            break;

        case distanceTeam:
            // 1
            fillHeight = `${navHeightObj['team']}%`;
            // activeNavName = 'team';
            activeNavIdx = 4;
            break;

        case distancePartner:
            // 1.5
            fillHeight = `${navHeightObj['partners']}%`;
            // activeNavName = 'partners';
            activeNavIdx = 5;
            break;

        case distanceContact:
            // 0.5
            fillHeight = `${navHeightObj['contact']}%`;
            // activeNavName = 'contact';
            activeNavIdx = 6;
            break;

        default:
            fillHeight = '0%';
            // activeNavName = 'hero';
            activeNavIdx = 0;
            break;
    }

    document.documentElement.style.setProperty('--fill', fillHeight);
    return activeNavIdx;
};

export const calculateFeatureDistance = (elementName, pos) => {
    let section = document.getElementById(elementName);
    let position = section?.offsetHeight;
    let distance = position;
    return distance;
};

export const productFeature1ScrollHandler = () => {
    let activeNavIdx;
    const scroll = window.scrollY;

    if (scroll > 1089 && scroll <= 1730) {
        activeNavIdx = 1;
    } else if (scroll > 1730 && scroll <= 1962) {
        activeNavIdx = 2;
    } else if (scroll > 1962 && scroll <= 2037) {
        activeNavIdx = 3;
    } else if (scroll > 2037 && scroll <= 2075) {
        activeNavIdx = 4;
    } else if (scroll > 3553 && scroll <= 4096) {
        activeNavIdx = 5;
    } else if (scroll > 4096 && scroll <= 4199) {
        activeNavIdx = 6;
    } else if (scroll > 4199 && scroll <= 4332) {
        activeNavIdx = 7;
    } else if (scroll > 4332 && scroll <= 4875) {
        activeNavIdx = 8;
    } else if (scroll > 4875 && scroll <= 5448) {
        activeNavIdx = 9;
    } else if (scroll > 5448) {
        activeNavIdx = 10;
    }

    return activeNavIdx;
};
