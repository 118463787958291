import React from 'react';
import styled from 'styled-components';
import { Subtitle1, Text2 } from './common.styles.font';
import { desktopViews, tabletViews } from '../utils/media';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 1rem;
    box-shadow: 0 4px 20px 0 rgba(216, 216, 244, 0.35);
    background: #fff;
    border-radius: var(--card-bd-r);
    padding: 2.4rem 2.4rem 3rem;
    gap: 1.2rem;

    /* max-width: 1040px */
    @media ${desktopViews.xxs} {
        padding: 2.4rem 2.2rem 3.2rem;
    }

    /* max-width: 768px */
    @media ${tabletViews.sm} {
        align-items: center;
        text-align: center;
    }
`;

const Icon = styled.div`
    color: var(--card-primary-icon);
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 50%;
    display: grid;
    place-items: center;

    .icon {
        transform: scale(1.2);
    }

    .icon-time,
    .icon-check {
        width: 2.5rem;
    }

    .icon-ruler,
    .icon-channel {
        width: 2.4rem;
        padding-bottom: 1px;
    }

    /* max-width: 768px */
    @media ${tabletViews.sm} {
        width: 4rem;
        height: 4rem;
        margin-bottom: 1.4rem;

        .icon-time,
        .icon-check {
            width: 2.2rem;
        }

        .icon-ruler,
        .icon-channel {
            width: 2.1rem;
        }
    }
`;

interface Props {
    curLang: string;
    title: string;
    txt: string;
    icon: any;
}

const CardIcon: React.FC<Props> = ({ curLang, title, txt, icon }) => {
    return (
        <Wrapper data-aos-delay="160" data-aos="fade-up">
            <Icon>{icon}</Icon>
            <Subtitle1 className={`lang-${curLang}`}>{title}</Subtitle1>
            <Text2 className={`lang-${curLang}`}>{txt}</Text2>
        </Wrapper>
    );
};

export default CardIcon;
