import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UilArrowUpRight } from '@iconscout/react-unicons';
import { StyledWrapperStandard, StyledInner, TitleWrapper, IntroWrapper, HighlightWrapper } from '../../components/common.style';
import { desktopViews, tabletViews } from '../../utils/media';
import { H4Title, HighlightText, Text1 } from '../../components/common.styles.font';
import CategoryIcon from '../../assets/icons/category.svg';
import ExtensionIcon from '../../assets/icons/extension.svg';
import WidgetsIcon from '../../assets/icons/widgets.svg';
import AutonewIcon from '../../assets/icons/autorenew.svg';
import DatabaseIcon from '../../assets/icons/database.svg';
import ModelingIcon from '../../assets/icons/modeling.svg';
import Button from '../../components/buttons/Button';

const Inner = styled(StyledInner)`
    max-width: 92rem;
`;

const CardsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, minmax(20rem, 1fr));
    justify-content: space-between;
    gap: 3rem;

    /* max-width: 820px */
    @media ${tabletViews.lg} {
        grid-template-columns: repeat(2, minmax(20rem, 1fr));
    }

    /* max-width: 600px */
    @media ${tabletViews.xs} {
        grid-template-columns: 1fr;
        gap: 2.4rem;
    }
`;

const Card = styled.div`
    padding: 1.2rem;
    background: #fff;
    border-radius: var(--overview-bd-r-lg);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    padding: 2.4rem 2.2rem;
    box-shadow: 0 4px 20px 0 rgba(216, 216, 244, 0.35);

    &.card-de {
        padding: 2rem 2.2rem;
    }

    /* max-width: 600px */
    @media ${tabletViews.xs} {
        width: 100%;
        border-radius: var(--overview-bd-r-sm);
        display: grid;
        grid-template-columns: 4.5rem 1fr;
        gap: 1rem;
        padding: 2rem 2.2rem 2rem 1rem;

        &.card-de {
            padding: 2rem 2.2rem 2rem 1rem;
        }
    }
`;

const CardIcon = styled.div`
    color: var(--card-secondary-icon);
    width: 4.5rem;
    height: 4.5rem;
    display: grid;
    place-items: center;

    .icon-repeated-docs {
        transform: scale(0.9);
    }

    .icon-location {
        transform: scale(1.2);
    }

    .icon-file,
    .icon-channel {
        transform: scale(1.3);
    }

    .icon-network {
        transform: scale(1.4);
    }

    /* max-width: 1200px */
    @media ${desktopViews.xs} {
        .icon-location {
            transform: scale(1.1);
        }

        .icon-file,
        .icon-channel {
            transform: scale(1.2);
        }

        .icon-network {
            transform: scale(1.3);
        }
    }

    /* max-width: 600px */
    @media ${tabletViews.xs} {
        .icon-repeated-docs {
            transform: scale(0.85);
        }

        .icon-location {
            transform: scale(1);
        }

        .icon-file,
        .icon-channel {
            transform: scale(1.1);
        }

        .icon-network {
            transform: scale(1.2);
        }
    }
`;

const CardText = styled(Text1)`
    font-weight: 600;
    line-height: 1.4;
    letter-spacing: 0;
    text-align: center;

    &.lang-pl {
        font-weight: 500;
    }

    /* max-width: 600px */
    @media ${tabletViews.xs} {
        text-align: left;
    }
`;

interface Props {
    curLang: string;
}

const ServicesSection: React.FC<Props> = ({ curLang }) => {
    const { t } = useTranslation();

    return (
        <StyledWrapperStandard id="main__services" className="bg-secondary">
            <Inner>
                <TitleWrapper className="title-card-wrapper">
                    <H4Title className="title-span-primary" data-aos-delay="80" data-aos="fade-up">
                        <span>{t('main.services.title')}</span>
                    </H4Title>
                </TitleWrapper>
                <IntroWrapper data-aos-delay="80" data-aos="fade-up">
                    <Text1 className={`lang-${curLang}`}>{t('main.services.intro')}</Text1>
                </IntroWrapper>

                <HighlightWrapper className="center">
                    <HighlightText className="small">{t('main.services.highlight')}</HighlightText>
                    <Button fs="20" width="fit-content" appearance="secondary-subtle" txt={t('common.btn.discoverMore')} icon={<UilArrowUpRight className="icon icon-arrow-up" />} handleClick={() => window.open(`/${curLang}/services`, '_blank')} />
                </HighlightWrapper>

                <CardsWrapper data-aos-delay="200" data-aos="fade-up">
                    {/* Card 1 */}
                    <Card className={`card-${curLang}`}>
                        <CardIcon>
                            <img className="icon icon-category" src={CategoryIcon} alt={t('main.services.point1')} />
                        </CardIcon>
                        <CardText className={`lang-${curLang}`}>{t('main.services.point1')}</CardText>
                    </Card>

                    {/* Card 2 */}
                    <Card className={`card-${curLang}`}>
                        <CardIcon>
                            <img className="icon icon-extenstion" src={ExtensionIcon} alt={t('main.services.point2')} />
                        </CardIcon>
                        <CardText className={`lang-${curLang}`}>{t('main.services.point2')}</CardText>
                    </Card>

                    {/* Card 3 */}
                    <Card className={`card-${curLang}`}>
                        <CardIcon>
                            <img className="icon icon-widgets" src={WidgetsIcon} alt={t('main.services.point3')} />
                        </CardIcon>
                        <CardText className={`lang-${curLang}`}>{t('main.services.point3')}</CardText>
                    </Card>

                    {/* Card 4 */}
                    <Card className={`card-${curLang}`}>
                        <CardIcon>
                            <img className="icon icon-autonew" src={AutonewIcon} alt={t('main.services.point4')} />
                        </CardIcon>
                        <CardText className={`lang-${curLang}`}>{t('main.services.point4')}</CardText>
                    </Card>

                    {/* Card 5 */}
                    <Card className={`card-${curLang}`}>
                        <CardIcon>
                            <img className="icon icon-database" src={DatabaseIcon} alt={t('main.services.point5')} />
                        </CardIcon>
                        <CardText className={`lang-${curLang}`}>{t('main.services.point5')}</CardText>
                    </Card>

                    {/* Card 6 */}
                    <Card className={`card-${curLang}`}>
                        <CardIcon>
                            <img className="icon icon-modeling" src={ModelingIcon} alt={t('main.services.point6')} />
                        </CardIcon>
                        <CardText className={`lang-${curLang}`}>{t('main.services.point6')}</CardText>
                    </Card>
                </CardsWrapper>
            </Inner>
        </StyledWrapperStandard>
    );
};

export default ServicesSection;
