export const desktopViews = {
    xxs: '(max-width: 1040px)',
    xs: '(max-width: 1200px)',
    sm: '(max-width: 1366px)',
    lg: '(max-width: 1440px)',
    xlg: '(max-width: 1920px)',
};

export const tabletViews = {
    xxs: '(max-width: 540px)',
    xs: '(max-width: 600px)',
    sm: '(max-width: 768px)',
    lg: '(max-width: 820px)',
    xlg: '(max-width: 992px)',
};

export const mobileViews = {
    xs: '(max-width: 360px)',
    sm: '(max-width: 375px)',
    lg: '(max-width: 390px)',
    xlg: '(max-width: 480px)',
};