import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    StyledWrapperStandard,
    StyledInner,
    StyledQuoteInner,
} from '../../components/common.style';
import { H2Title } from '../../components/common.styles.font';
import FeatureContent from '../../components/FeatureContent';
import ArrowDownBtn from '../../components/buttons/ArrowDownBtn';
import { isInViewport } from '../../helpers/scrollIntoView';

interface Intro {
    id: string;

    txt: string;
}

interface Nav {
    id: string;
    idx: string;
    target: string;
    txt: string;
}

interface Content {
    id: string;
    idx: string;
    title: string;
    desc: string;
    img: any;
    alt: null | string;
    video: any;
    videoDesc: null | string;
}

interface Props {
    curLang: string;
    dataName: string;
    section: {
        moreBtnSection: {
            txt: string;
            targetEl: string;
        };
        intro: {
            [key: string]: Intro[];
        };
        nav: {
            [key: string]: Nav[];
        };
        content: {
            [key: string]: Content[];
        };
    };
    openLightbox: (arg0: any) => void;
}

const ProductFeatureSection: React.FC<Props> = ({
    curLang,
    dataName,
    section,
    openLightbox,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (!section.moreBtnSection) return;
        const scrollText = () => {
            const moreEl = document.getElementById(`product-${dataName}__more`);
            const bg = document.getElementById(`product-${dataName}__bg-whole`);

            if (moreEl) {
                if (isInViewport(moreEl)) {
                    moreEl?.classList.add('active');
                    bg?.classList.add('active');
                } else {
                    moreEl?.classList.remove('active');
                    bg?.classList.remove('active');
                }
            }
        };

        window.addEventListener('scroll', () => scrollText());
        return window.removeEventListener('scroll', scrollText);
    }, []);

    return (
        <StyledWrapperStandard id={`product-${dataName}__features`}>
            <StyledInner>
                {section.intro.section1 && (
                    <FeatureContent
                        curLang={curLang}
                        dataName={dataName}
                        order="1"
                        category="left"
                        introArr={section.intro.section1}
                        navArr={section.nav.section1}
                        featureArr={section.content.section1}
                        openLightbox={openLightbox}
                    />
                )}

                {section.moreBtnSection && (
                    <StyledQuoteInner
                        id={`product-${dataName}__more`}
                        className="product"
                    >
                        <H2Title className={`lang-${curLang} center`}>
                            {t(section.moreBtnSection.txt)}
                        </H2Title>
                        <ArrowDownBtn
                            category="product"
                            targetElId={section.moreBtnSection.targetEl}
                            place="start"
                        />
                    </StyledQuoteInner>
                )}

                {section.intro.section2 && (
                    <FeatureContent
                        curLang={curLang}
                        dataName={dataName}
                        order="2"
                        category="right"
                        introArr={section.intro.section2}
                        navArr={section.nav.section2}
                        featureArr={section.content.section2}
                        openLightbox={openLightbox}
                    />
                )}
            </StyledInner>
        </StyledWrapperStandard>
    );
};

export default ProductFeatureSection;
