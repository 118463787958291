import React from 'react';
import { useTranslation } from 'react-i18next';
import { UilCheck } from '@iconscout/react-unicons';
import styled from 'styled-components';
import { Subtitle1, Text3 } from './common.styles.font';
import Button from './buttons/Button';
import { tabletViews } from '../utils/media';
import { contactLink } from '../utils/links';

const Wrapper = styled.div`
    position: relative;
    font-family: 'Assistant', 'Roboto', sans-serif;
    letter-spacing: 0.2px;
    text-align: center;
    background: var(--card-bg);
    border: 1px solid var(--card-bd);
    box-shadow: var(--card-shadow);
    padding: 2rem 1.6rem 2.6rem;
    border-radius: var(--card-bd-r);

    &.lang-pl {
        font-family: 'Noto Sans Condensed', 'Roboto', sans-serif;
        letter-spacing: 0.6px;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 10px;
        border-radius: 5px 5px 0 0;
    }
`;

const Inner = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: 24rem 1fr;
`;

const CardHeader = styled.div`
    padding: 0 1.4rem;
    display: grid;
    border-bottom: 1px solid var(--card-line);

    .name.basic {
        color: var(--secondary-900);
    }

    .name.enterprise {
        color: var(--tertiary-800);
    }
`;

const PriceWrapper = styled.div`
    height: 100%;
    place-items: center;
    padding-bottom: 0.8rem;

    p {
        margin-bottom: 0.6rem;
    }

    .method {
        font-weight: 400;
        font-size: 1.6rem;
        color: var(--txt-inactive);
    }

    .billed {
        font-style: italic;
        font-weight: 400;
        font-size: 1.4rem;
        letter-spacing: 0.4px;
        color: var(--txt-disabled);
    }
`;

const Price = styled.div`
    margin: 0 auto;
    width: fit-content;
    position: relative;
    margin-bottom: 1.6rem;

    h4.price {
        font-family: 'Roboto', sans-serif;
        font-weight: 600;
        font-size: 4.2rem;
        color: var(--txt-active);
    }

    h4.custom-price {
        font-weight: 600;
        font-size: 2rem;
        color: var(--txt-active);
        max-width: 40rem;
    }

    /* max-width: 600px */
    @media ${tabletViews.xs} {
        h4.custom-price {
            font-size: 1.9rem;
        }
    }

    .exclusion {
        position: absolute;
        top: 1rem;
        right: 0;
        transform: translateX(110%);
        font-weight: 400;
        font-size: 1.2rem;
        color: var(--txt-inactive);
    }
`;

const CardBody = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 3rem;
`;

const List = styled.ul`
    padding: 2.4rem 2rem 1rem;
`;

const ListItem = styled.li`
    display: flex;
    gap: 1rem;
    text-align: left;
    margin-bottom: 0.6rem;
`;

const Icon = styled.div`
    width: 2rem;

    .icon {
        width: 2rem;
        transform: translateY(1px);
        color: var(--tertiary-500);
    }
`;

const BtnsWrapper = styled.div`
    display: grid;
    row-gap: 1.4rem;
`;

interface Feature {
    id: string;
    txt: string;
}

interface Props {
    curLang: string;
    category: string;
    title: string;
    price: {
        title: string;
        unit: string;
        method: string;
    };
    customPrice: null | string;
    featuresArr: Feature[];
    isFreeTrialBtn: boolean;
}

const PricingCard: React.FC<Props> = ({
    curLang,
    category,
    title,
    price,
    customPrice,
    featuresArr,
    isFreeTrialBtn,
}) => {
    const { t } = useTranslation();
    return (
        <Wrapper className={`${category} lang-${curLang}`}>
            <Inner>
                <CardHeader>
                    <Subtitle1 className={`name ${category} lang-${curLang}`}>
                        {t(title)}
                    </Subtitle1>
                    {price && (
                        <PriceWrapper>
                            <Price>
                                <h4 className="price">{t(price?.title)}</h4>
                                <p className="exclusion">
                                    {t('common.pricing.exclVAT')}
                                </p>
                            </Price>
                            <p className="method">{t(price?.unit)}</p>
                            <p className="billed">{t(price?.method)}</p>
                        </PriceWrapper>
                    )}

                    {customPrice && (
                        <PriceWrapper>
                            <Price>
                                <h4 className="custom-price">
                                    {t(customPrice)}
                                </h4>
                            </Price>
                        </PriceWrapper>
                    )}
                </CardHeader>

                <CardBody>
                    <List>
                        {featuresArr.map(({ id, txt }) => (
                            <ListItem key={id}>
                                <Icon>
                                    <UilCheck className="icon icon-check" />
                                </Icon>
                                <Text3 className={`lang-${curLang}`}>
                                    {t(txt)}
                                </Text3>
                            </ListItem>
                        ))}
                    </List>

                    <BtnsWrapper>
                        {isFreeTrialBtn && (
                            <Button
                                fs="17"
                                appearance="secondary-outline"
                                txt={t('common.btn.getStartedWithfreeTrial')}
                                handleClick={() =>
                                    window.open(
                                        `https://upointdms.com/${curLang}/landing`,
                                        '_blank',
                                    )
                                }
                            />
                        )}
                        <Button
                            fs="17"
                            appearance="secondary"
                            txt={t('common.btn.contactUs')}
                            handleClick={() =>
                                (window.location.href = `mailto:${contactLink}`)
                            }
                        />
                    </BtnsWrapper>
                </CardBody>
            </Inner>
        </Wrapper>
    );
};

export default PricingCard;
