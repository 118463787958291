export const headerHeight = 5;
export const bottomHeight = 5.8;

export const tooltipIndex = 9997;

export const menuWrapperIndex = 9003;
export const hamburgerMenuZIndex = 9002;
export const imageLightboxZIndex = 9001;

export const popWrapperIndex = 8998;

export const headerIndex = 7999;
export const bottomIndex = 7998;
export const sideBarIndex = 7998;

export const mainPageBgActiveIndex = 999;
export const solutionIntroIndex = 999;
export const mainPageBgIndex = 0;
