import React, { useRef, useState, useEffect } from 'react';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { UilAngleDown, UilBars, UilTimes } from '@iconscout/react-unicons';
import { Wrapper, Logo, Nav, ListItem, ListMenuBtn, ListMenu, LinkBtn, Tag, ListMenuLink, HamburgerBtn, HamburgerMenu, HamburgerMenuListItem, HamburgerMenuListBtn, MenuBtnsWrapper, HamburgerMenuFooter } from './style.js';
import BlubinaLogo from '../../assets/logo/logo-blubina.png';
import { mainLanguages } from '../../utils/languages';
import { contactLink, hamburgerMenuList, productList } from '../../utils/links';
import useScroll from '../../hooks/useScroll';
import useClickOutside from '../../hooks/useClickOutside';
import Button from '../buttons/Button';
import Footer from '../../sections/footer/Footer';

interface Props {
    curView: string | null;
    curLang: string;
    setCurLang: React.Dispatch<React.SetStateAction<string>>;
}

const Header: React.FC<Props> = ({ curView, curLang, setCurLang }) => {
    const { t } = useTranslation();
    const body = document.body;
    const langBtnRef = useRef<HTMLLIElement | null>(null);
    const productBtnRef = useRef<HTMLLIElement | null>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const locationArr = location.pathname.split('/');
    const isProductPage = locationArr.includes('product');
    const activeProduct = isProductPage && locationArr[3];
    const [isLangMenuOpen, setLangMenuOpen] = useState<boolean>(false);
    const [isProductsMenuOpen, setProductsMenuOpen] = useState<boolean>(false);
    const [isHamburgerMenuOpen, setHamburgerMenuOpen] = useState<boolean>(false);

    useClickOutside(langBtnRef, () => setLangMenuOpen(false));
    useClickOutside(productBtnRef, () => setProductsMenuOpen(false));
    const scroll = useScroll(curView === 'homepage' ? 'main__hero' : 'product__hero');

    // Close the hamburger menu when resizing above 600px
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 600 && isHamburgerMenuOpen) {
                setHamburgerMenuOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, [isHamburgerMenuOpen]);

    // Close the hamburger menu when resizing above 600px
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 600 && isProductsMenuOpen) {
                setProductsMenuOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, [isProductsMenuOpen]);

    const handleLangClick = (code: string) => {
        setCurLang(code);
        i18n.changeLanguage(code);
        localStorage.setItem('lang', JSON.stringify(code));
        const curPath = location.pathname;
        const newPath = curPath.replace(/^\/(en|de|pl|zh)/, `/${code}`);
        navigate(newPath);
        setLangMenuOpen(false);
    };

    const handleHamburgerMenuClick = () => {
        if (isHamburgerMenuOpen) {
            setHamburgerMenuOpen(false);
            body.style.overflow = 'scroll';
        } else {
            setHamburgerMenuOpen(true);
            body.style.overflow = 'hidden';
        }
    };

    return (
        <Wrapper className={!isHamburgerMenuOpen && scroll === 'down' ? !isHamburgerMenuOpen && 'hide' : scroll === 'up' ? 'show' : ''}>
            <Logo className={isHamburgerMenuOpen ? 'active' : ''} href={`/${curLang}`} target="_blank">
                <img className="hero-header_logo-img" src={BlubinaLogo} alt="logo" />
            </Logo>
            <Nav className={isHamburgerMenuOpen ? 'active' : ''}>
                {/* Language Selector */}
                <ListItem ref={langBtnRef} className="header__nav-lang">
                    <ListMenuBtn onClick={() => setLangMenuOpen(!isLangMenuOpen)}>
                        <UilAngleDown className="icon icon-arrow-down" />
                        <span>{curLang}</span>
                    </ListMenuBtn>

                    {isLangMenuOpen && (
                        <ListMenu>
                            {mainLanguages.map(({ id, code, name }) => (
                                <li key={id}>
                                    <LinkBtn className={i18n.resolvedLanguage === code ? 'active' : ''} onClick={() => handleLangClick(code)}>
                                        {name}
                                    </LinkBtn>
                                </li>
                            ))}
                        </ListMenu>
                    )}
                </ListItem>

                {/* Products Selector */}
                <ListItem ref={productBtnRef} className="header__nav-products">
                    <ListMenuBtn onClick={() => setProductsMenuOpen(!isProductsMenuOpen)}>
                        <UilAngleDown className="icon icon-arrow-down" />
                        <span>{t('main.nav.products')}</span>
                    </ListMenuBtn>

                    {isProductsMenuOpen && (
                        <ListMenu>
                            {productList.map(({ id, name, txt, active, btnAction }) => (
                                <li key={id}>
                                    <LinkBtn
                                        className={activeProduct === name ? 'active' : ''}
                                        onClick={() => {
                                            btnAction(curLang);
                                            setProductsMenuOpen(false);
                                        }}
                                    >
                                        {txt}
                                        {!active && <Tag>{t('common.txt.comingSoon')}</Tag>}
                                    </LinkBtn>
                                </li>
                            ))}
                        </ListMenu>
                    )}
                </ListItem>

                {/* Services */}
                <ListItem className="header__nav-services">
                    <ListMenuLink href={`/${curLang}/services`} target="_blank">
                        <span>{t('main.nav.services')}</span>
                    </ListMenuLink>
                </ListItem>

                {/* Contact us */}
                <ListItem className="header__nav-contact">
                    <ListMenuLink href={`mailto:${contactLink}`} target="_blank">
                        <span>{t('common.btn.contactUs')}</span>
                    </ListMenuLink>
                </ListItem>

                {/* Hamburger menu */}
                <ListItem className="header__nav-hamburger">
                    <HamburgerBtn onClick={handleHamburgerMenuClick}>{isHamburgerMenuOpen ? <UilTimes className="icon icon-close" /> : <UilBars className="icon icon-bar" />}</HamburgerBtn>
                </ListItem>
            </Nav>

            <HamburgerMenu className={isHamburgerMenuOpen ? 'active' : ''}>
                <ul>
                    {hamburgerMenuList.map(({ id, txt, btnAction, childrenList }) => (
                        <HamburgerMenuListItem key={id} className="main">
                            <HamburgerMenuListBtn
                                className="main"
                                onClick={() => {
                                    btnAction && btnAction(curLang);
                                    setHamburgerMenuOpen(false);
                                }}
                                disabled={btnAction ? false : true}
                            >
                                {t(txt)}
                                {!btnAction && <UilAngleDown className="icon icon-arrow-down" />}
                            </HamburgerMenuListBtn>
                            {childrenList && (
                                <ul>
                                    {childrenList.map(({ id, txt, btnAction }) => (
                                        <HamburgerMenuListItem key={id} className="sub">
                                            <HamburgerMenuListBtn
                                                className="sub"
                                                onClick={() => {
                                                    btnAction(curLang);
                                                    setHamburgerMenuOpen(false);
                                                }}
                                            >
                                                {t(txt)}
                                            </HamburgerMenuListBtn>
                                        </HamburgerMenuListItem>
                                    ))}
                                </ul>
                            )}
                        </HamburgerMenuListItem>
                    ))}
                </ul>

                <MenuBtnsWrapper>
                    <Button fs="15" appearance="primary" txt={t('common.btn.contactUs')} handleClick={() => (window.location.href = `mailto:${contactLink}`)} />
                </MenuBtnsWrapper>

                <HamburgerMenuFooter>
                    <Footer curView={curView} curLang={curLang} />
                </HamburgerMenuFooter>
            </HamburgerMenu>
        </Wrapper>
    );
};

export default Header;
