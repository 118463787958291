const { default: styled } = require('styled-components');
const { mainPageBgIndex, mainPageBgActiveIndex } = require('../../utils/common');

export const Wrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    background-color: var(--bg-primary);
`;

export const Bg = styled.div`
    width: 100%;
    height: 100%;
    background: var(--bg-tertiary-d);
    position: absolute;
    top: 0;
    left: 0;
    z-index: ${mainPageBgIndex};
    opacity: 0;
    transition: all 0.5s ease-in-out;

    &.active {
        opacity: 1;
        z-index: ${mainPageBgActiveIndex};
    }
`;

export const ContentWrapper = styled.div`
    width: 100%;
    padding: var(--main-top-padding) var(--main-side-padding) var(--main-bottom-padding);
`;

export const ContentInner = styled.div`
    max-width: 82rem;
    display: flex;
    flex-direction: column;
    gap: 8rem;
`;

export const Section = styled.div``;

export const SectionIntro = styled.div`
    margin-bottom: 2.4rem;

    h4 {
        text-align: left;
        margin-bottom: 1.6rem;
    }
`;

export const SectionList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
`;

export const SectionListItem = styled.li`
    position: relative;
    left: -1.4rem;
    list-style-position: outside;
    list-style-type: circle;
    left: 1.4rem;

    p {
        font-weight: 700;
        color: var(--txt-active);
    }
`;
