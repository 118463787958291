import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Head from '../../components/Head';
import Lightbox from '../../components/Lightbox';
import ProductHeroSection from '../../sections/productHero/ProductHeroSection';
import ServicesContentSection from '../../sections/servicesContent/ServicesContentSection';
import ProductRequestSection from '../../sections/productRequest/ProductRequestSection';
import GoTopBtn from '../../components/buttons/GoTopBtn';
import { Wrapper, Bg } from './styles';

interface Props {
    curLang: string;
    productDetailsObj: any;
}

const ServicesPage: React.FC<Props> = ({ curLang, productDetailsObj }) => {
    const { t } = useTranslation();
    const [popupImg, setPopupImg] = useState<any>(null);
    const [isLightboxOpen, setLightboxOpen] = useState(false);

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                closeLightbox();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const openLightbox = (targetImg: any) => {
        setLightboxOpen(true);
        setPopupImg(targetImg);
        document.body.classList.add('popup-open');
    };

    const closeLightbox = () => {
        setPopupImg(null);
        setLightboxOpen(false);
        document.body.classList.remove('popup-open');
    };

    console.log(productDetailsObj.overview);

    return (
        <>
            <Head title={t(productDetailsObj.appTitle)} desc={t(productDetailsObj.appDesc)} />
            <Wrapper>
                <Bg id={`product-${productDetailsObj.dataName}__bg-whole`} />
                <Lightbox popupImg={popupImg} isLightboxOpen={isLightboxOpen} closeLightbox={closeLightbox} />
                <ProductHeroSection dataName={productDetailsObj.dataName} curLang={curLang} heroDetails={productDetailsObj.heroDetails} openLightbox={openLightbox} />

                <ServicesContentSection overview={productDetailsObj.overview} />

                <ProductRequestSection curLang={curLang} section={productDetailsObj.requestSection} />
                <GoTopBtn />
            </Wrapper>
        </>
    );
};

export default ServicesPage;
