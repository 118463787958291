import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(LanguageDetector)
    .use(backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        supportedLngs: ['de', 'en', 'pl', 'zh'],
        fallbackLng: ['de', 'en'],
        keySeparator: '.',
        nsSeparator: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        detection: {
            order: [
                'path',
                'localStorage',
                'cookie',
                'navigator',
                'querystring',
                'htmlTag',
                'subdomain',
            ],
            caches: ['cookie'],
        },
        backend: {
            loadPath: '/assets/locales/{{lng}}/translation.json',
        },
        react: {
            bindI18n: 'languageChanged',
            bindI18nStore: '',
            transEmptyNodeValue: '',
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
            useSuspense: true,
        },
    });

export default i18n;
