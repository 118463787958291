import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StyledInner, StyledWrapper } from '../../components/common.style';
import Cube from '../../components/Cube';
import { tabletViews } from '../../utils/media';
import { H1Title, Text2 } from '../../components/common.styles.font';

const Wrapper = styled(StyledWrapper)`
    min-height: 100vh;
    min-height: 90rem;
    display: flex;
    align-items: center;
    padding-top: 18rem;
    padding-bottom: 18rem;
    padding-right: var(--main-right-padding);
    padding-left: var(--main-left-padding);

    /* max-width: 820px */
    @media ${tabletViews.lg} {
        padding-top: 14rem;
        padding-bottom: 14rem;
    }

    /* max-width: 600px */
    @media ${tabletViews.xs} {
        display: block;
        padding-top: 12rem;
        min-height: 80rem;
    }
`;

const Inner = styled(StyledInner)`
    display: grid;
    grid-template-columns:
        minmax(40rem, 68rem)
        minmax(25rem, 1fr);
    grid-template-areas: 'text img';
    justify-items: center;
    align-items: center;
    gap: var(--gutter);

    /* max-width: 820px */
    @media ${tabletViews.lg} {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
`;

const TextWrapper = styled.div`
    grid-area: text;

    .desc {
        font-weight: 400;
    }
`;

interface Props {
    curLang: string;
}

const HeroSection: React.FC<Props> = ({ curLang }) => {
    const { t } = useTranslation();

    return (
        <Wrapper id="main__hero" className="bg-primary-gradient">
            <Inner className={`inner-${curLang}`}>
                <Cube data-aos="fade-up" data-aos-delay="600" />
                <TextWrapper data-aos="fade-up" data-aos-delay="300">
                    <H1Title className="main">
                        {t('main.hero.title1')}
                        <span>{t('main.hero.highlight1')}</span>
                        {t('main.hero.title2')}{' '}
                        <span>{t('main.hero.highlight2')}</span>
                        {t('main.hero.title3')}
                    </H1Title>

                    <Text2 className={`lang-${curLang}`}>
                        {t('main.hero.desc')}
                    </Text2>
                </TextWrapper>
            </Inner>
        </Wrapper>
    );
};

export default HeroSection;
