import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UilArrowRight } from '@iconscout/react-unicons';
import { StyledGridInner, StyledWrapper } from '../../components/common.style';
import ArrowDownBtn from '../../components/buttons/ArrowDownBtn';
import { desktopViews, mobileViews, tabletViews } from '../../utils/media';
import { H3Title, H5Intro } from '../../components/common.styles.font';
import Arrow from '../../assets/icons/arrow_1440.png';

const Wrapper = styled(StyledWrapper)`
    transition: all 0.3s ease-in-out;
`;

const Inner = styled(StyledGridInner)`
    .title-wrapper {
        grid-area: title;
    }

    .points-wrapper {
        grid-area: points;
    }
`;

const Title = styled.div`
    width: 100%;
`;

const PointsBtnWrapper = styled.div`
    padding-top: 8rem;
    display: flex;
    flex-direction: column;
    gap: 8rem;

    /* max-width: 1200px */
    @media ${desktopViews.xs} {
        padding-top: 4rem;
    }

    /* max-width: 480px */
    @media ${mobileViews.xlg} {
        padding-top: 3rem;
    }
`;

const Points = styled.ul`
    width: 100%;
`;

const Point = styled.li`
    width: 100%;
    display: flex;
    gap: 4rem;
    margin-bottom: 2.2rem;

    .icon-arrow {
        display: none;
    }

    /* max-width: 820px */
    @media ${tabletViews.lg} {
        margin-bottom: 2rem;
        gap: 2.6rem;

        .img-arrow {
            width: 4rem;
        }
    }

    /* max-width: 480px */
    @media ${mobileViews.xlg} {
        margin-bottom: 1.6rem;
        gap: 1.4rem;
        align-items: center;

        .img-arrow {
            display: none;
        }
        .icon-arrow {
            display: block;
        }
    }
`;

interface Props {
    curLang: string;
}

const ChallengeSection: React.FC<Props> = () => {
    const { t } = useTranslation();

    return (
        <Wrapper id="main__challenges" className="bg-primary">
            <Inner className="challenges">
                <Title className="title-wrapper" data-aos-delay="80" data-aos="fade-up">
                    <H3Title>{t('main.challenges.title')}</H3Title>
                </Title>

                <PointsBtnWrapper className="points-wrapper" data-aos-delay="80" data-aos="fade-up">
                    <Points>
                        <Point>
                            <img className="img img-arrow" src={Arrow} alt="arrow" />
                            <UilArrowRight className="icon icon-arrow" />
                            <H5Intro>{t('main.challenges.point1')}</H5Intro>
                        </Point>
                        <Point>
                            <img className="img img-arrow" src={Arrow} alt="arrow" />
                            <UilArrowRight className="icon icon-arrow" />
                            <H5Intro>{t('main.challenges.point2')}</H5Intro>
                        </Point>
                        <Point>
                            <img className="img img-arrow" src={Arrow} alt="arrow" />
                            <UilArrowRight className="icon icon-arrow" />
                            <H5Intro>{t('main.challenges.point3')}</H5Intro>
                        </Point>
                        <Point>
                            <img className="img img-arrow" src={Arrow} alt="arrow" />
                            <UilArrowRight className="icon icon-arrow" />
                            <H5Intro>{t('main.challenges.point4')}</H5Intro>
                        </Point>
                        <Point>
                            <img className="img img-arrow" src={Arrow} alt="arrow" />
                            <UilArrowRight className="icon icon-arrow" />
                            <H5Intro>{t('main.challenges.point5')}</H5Intro>
                        </Point>
                    </Points>
                    <ArrowDownBtn category="btn-arrow" targetElId="solutions-intro" place="center" />
                </PointsBtnWrapper>
            </Inner>
        </Wrapper>
    );
};

export default ChallengeSection;
