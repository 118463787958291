import { v4 as uuidv4 } from 'uuid';

export const mainLanguages = [
    {
        id: uuidv4(),
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },
    {
        id: uuidv4(),
        code: 'de',
        name: 'Deutsch',
        country_code: 'de',
    },
    { id: uuidv4(), code: 'pl', name: 'Polski', country_code: 'pl' },
    { id: uuidv4(), code: 'zh', name: '中文', country_code: 'tw' },
];

export const landingLanguages = [
    {
        id: uuidv4(),
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },
    {
        id: uuidv4(),
        code: 'de',
        name: 'Deutsch',
        country_code: 'de',
    },
    { id: uuidv4(), code: 'pl', name: 'Polski', country_code: 'pl' },
];
