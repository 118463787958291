import React from 'react';
import styled from 'styled-components';
import { UilArrowUp } from '@iconscout/react-unicons';
import { StyledBtn } from '../common.style';
import { tabletViews } from '../../utils/media';
import { headerIndex } from '../../utils/common';

const Wrapper = styled(StyledBtn)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    position: fixed;
    z-index: ${headerIndex};
    bottom: 3rem;
    right: 4rem;

    .icon-arrow {
        width: 2.6rem;
        height: 2.6rem;
        position: relative;
        z-index: 1;
        transform: translateY(-0.2rem);
    }

    &:hover .top-circle,
    &:active .top-circle {
        background: var(--btn-circle-top-h);
    }

    &:hover .top-circle::before {
        transform: scale(1.15);
    }

    &:hover .top-circle::after {
        transform: scale(1.6);
    }

    &:active .top-circle::before,
    &:active .top-circle::after {
        transform: scale(1);
    }

    /* max-width: 1536px */
    @media (max-width: 1536px) {
        .icon-arrow {
            width: 2.3rem;
            height: 2.3rem;
        }
    }

    /* max-width: 768px */
    @media ${tabletViews.sm} {
        display: none;
    }
`;

const CircleTop = styled.div`
    position: relative;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background: var(--btn-circle-top);
    display: grid;
    place-items: center;
    margin-bottom: 1rem;

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    &::before {
        background: var(--btn-circle-top-h-1);
        transition: transform 0.2s;
    }

    &::after {
        background: var(--btn-circle-top-h-2);
        transition: transform 0.3s;
    }

    &:hover,
    &:active {
        background: var(--btn-circle-top);
    }

    &:hover::before {
        transform: scale(1.15);
    }

    &:hover::after {
        transform: scale(1.35);
    }

    &:active::before,
    &:active::after {
        transform: scale(1);
    }

    /* max-width: 1536px */
    @media (max-width: 1536px) {
        width: 4.5rem;
        height: 4.5rem;
    }
`;

const GoTopBtn = () => {
    return (
        <Wrapper onClick={() => window.scrollTo(0, 0)}>
            <CircleTop className="top-circle">
                <UilArrowUp className="icon-arrow" />
            </CircleTop>
        </Wrapper>
    );
};

export default GoTopBtn;
