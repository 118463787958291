import React from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';

interface Props {
    title: string;
    desc: string;
}

const Head: React.FC<Props> = ({ title, desc }) => {
    return (
        <HelmetProvider>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={desc} />
            </Helmet>
        </HelmetProvider>
    );
};

export default Head;
