import React from 'react';
import i18n from '../i18n';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

export const Wrapper = styled.div`
    position: absolute;
    top: 1.6rem;
    right: 2rem;
    display: flex;
    align-items: center;
    gap: 1.2rem;
`;

export const LangBtn = styled.button`
    font-family: 'Roboto', 'Roboto Mono', sans-serif;
    letter-spacing: 0.1px;
    font-size: 1.6rem;
    font-weight: 500;
    color: var(--lang-btn-txt);
    padding-bottom: 0.3rem;
    border-bottom: 2px solid transparent;

    &:hover {
        color: var(--lang-btn-txt-h);
    }

    &:focus,
    &.active {
        color: var(--lang-btn-txt-f);
    }

    &:hover {
        display: block;
        border-bottom: 2px solid var(--lang-btn-txt-h);
    }

    &.active:hover {
        border-bottom: 2px solid transparent;
    }
`;

interface Lang {
    id: string;
    name: string;
    txt: string;
}

interface Props {
    curLang: string;
    langArr: Lang[];
    setCurLang: React.Dispatch<React.SetStateAction<string>>;
}

const LangBtns: React.FC<Props> = ({ curLang, langArr, setCurLang }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const handleLangClick = (lang: string) => {
        setCurLang(lang);
        i18n.changeLanguage(lang);
        localStorage.setItem('lang', JSON.stringify(lang));
        const curPath = location.pathname;
        const newPath = curPath.replace(/^\/(de|en|pl|zh)/, `/${lang}`);
        navigate(newPath);
    };

    return (
        <Wrapper>
            {langArr.map(({ id, name, txt }) => (
                <LangBtn
                    key={id}
                    className={curLang === name ? 'active' : ''}
                    onClick={() => handleLangClick(name)}
                >
                    {txt}
                </LangBtn>
            ))}
        </Wrapper>
    );
};

export default LangBtns;
