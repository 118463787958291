import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    Wrapper,
    MainBannerWrapper,
    MainTitleWrapper,
    Inner,
    Section,
    ContentWrapper,
    ContentDetails,
    OrderList,
    UnorderList,
    ListItem,
    ItemLink,
    ContactInfoWrapper,
} from './style';
import Head from '../../components/Head';
import LangBtns from '../../components/LangBtns';
import Footer from '../../sections/footer/Footer';
import {
    Subtitle1,
    Subtitle2,
    Text2,
    Text3,
} from '../../components/common.styles.font';
import { contactLink } from '../../utils/links';

const langArr = [
    { id: 'en', name: 'en', txt: 'EN' },
    { id: 'de', name: 'de', txt: 'DE' },
    { id: 'pl', name: 'pl', txt: 'PL' },
];

interface Props {
    appTitle: string;
    appDesc: string;
    curLang: string;
    setCurLang: React.Dispatch<React.SetStateAction<string>>;
}

const PrivacyPolicyPage: React.FC<Props> = ({
    appTitle,
    appDesc,
    curLang,
    setCurLang,
}) => {
    const { t } = useTranslation();

    const privacyPolicy = {
        legalBasic: [
            'privacyPolicy.legalBasic.detail1',
            'privacyPolicy.legalBasic.detail2',
            'privacyPolicy.legalBasic.detail3',
            'privacyPolicy.legalBasic.detail4',
            'privacyPolicy.legalBasic.detail5',
        ],
        disclosure: [
            'privacyPolicy.disclosure.desc1',
            'privacyPolicy.disclosure.desc2',
        ],
        dataTransfer: [
            'privacyPolicy.dataTransfer.desc1',
            'privacyPolicy.dataTransfer.desc2',
        ],
        provisionCreation: [
            'privacyPolicy.provisionCreation.detail1Point1',
            'privacyPolicy.provisionCreation.detail1Point2',
            'privacyPolicy.provisionCreation.detail1Point3',
            'privacyPolicy.provisionCreation.detail1Point4',
            'privacyPolicy.provisionCreation.detail1Point5',
            'privacyPolicy.provisionCreation.detail1Point6',
            'privacyPolicy.provisionCreation.detail1Point7',
            'privacyPolicy.provisionCreation.detail1Point8',
            'privacyPolicy.provisionCreation.detail1Point9',
            'privacyPolicy.provisionCreation.detail1Point10',
            'privacyPolicy.provisionCreation.detail1Point11',
        ],
        settlementContracts: [
            'privacyPolicy.settlementContracts.detail1',
            'privacyPolicy.settlementContracts.detail2',
            'privacyPolicy.settlementContracts.detail3',
            'privacyPolicy.settlementContracts.detail4',
        ],
        dataProtection: [
            'privacyPolicy.dataProtection.detail1',
            'privacyPolicy.dataProtection.detail2',
            'privacyPolicy.dataProtection.detail3',
            'privacyPolicy.dataProtection.detail4',
        ],
    };

    return (
        <>
            <Head title={appTitle} desc={appDesc} />
            <Wrapper>
                <MainBannerWrapper>
                    <MainTitleWrapper>
                        <h1>{t('privacyPolicy.main.title')}</h1>
                        <Text3 className={`lang-${curLang}`}>
                            {t('privacyPolicy.main.lastUpdated')}
                        </Text3>
                    </MainTitleWrapper>

                    <LangBtns
                        curLang={curLang}
                        setCurLang={setCurLang}
                        langArr={langArr}
                    />
                </MainBannerWrapper>

                <Inner>
                    <Section>
                        {/*---------- Introduction ----------*/}
                        <ContentWrapper>
                            <ContentDetails>
                                <Text2
                                    className={`lang-${curLang} section-intro`}
                                >
                                    {t('privacyPolicy.main.intro')}
                                </Text2>
                            </ContentDetails>
                        </ContentWrapper>

                        {/*---------- Name and contact details of the responsible person(s) ----------*/}
                        <ContentWrapper>
                            <Subtitle1
                                className={`lang-${curLang} section-title`}
                            >
                                {t('privacyPolicy.nameContact.title')}
                            </Subtitle1>
                            <ContentDetails>
                                <Text2
                                    className={`lang-${curLang} section-intro`}
                                >
                                    {t('privacyPolicy.nameContact.desc')}
                                </Text2>
                                <ContactInfoWrapper>
                                    <Text2
                                        className={`lang-${curLang} company-name`}
                                    >
                                        Blubina GmbH
                                    </Text2>
                                    <Text3
                                        className={`lang-${curLang} company-info`}
                                    >
                                        {t(
                                            'privacyPolicy.nameContact.companyStr',
                                        )}
                                    </Text3>
                                    <Text3
                                        className={`lang-${curLang} company-info`}
                                    >
                                        {t(
                                            'privacyPolicy.nameContact.companyZipCountry',
                                        )}
                                    </Text3>
                                    <Text3
                                        className={`lang-${curLang} company-info`}
                                    >
                                        {t(
                                            'privacyPolicy.nameContact.companyDirector',
                                        )}
                                    </Text3>
                                    <Text3
                                        className={`lang-${curLang} company-info`}
                                    >
                                        {t(
                                            'privacyPolicy.nameContact.companyCommercialRegister',
                                        )}
                                    </Text3>

                                    <Text3
                                        className={`lang-${curLang} company-info`}
                                    >
                                        {t(
                                            'privacyPolicy.nameContact.companyRegisterCourt',
                                        )}
                                    </Text3>
                                    <Text3
                                        className={`lang-${curLang} company-info`}
                                    >
                                        {t(
                                            'privacyPolicy.nameContact.companyEmailAddress',
                                        )}
                                        <ItemLink
                                            className={`lang-${curLang}`}
                                            href={`mailto:${contactLink}`}
                                        >
                                            {contactLink}
                                        </ItemLink>
                                    </Text3>
                                </ContactInfoWrapper>
                            </ContentDetails>
                        </ContentWrapper>

                        {/*---------- Data Protection Officer ----------*/}
                        <ContentWrapper>
                            <Subtitle1
                                className={`lang-${curLang} section-title`}
                            >
                                {t('privacyPolicy.dataProtectionOfficer.title')}
                            </Subtitle1>
                            <ContentDetails>
                                <ContactInfoWrapper>
                                    <Text2 className={`lang-${curLang}`}>
                                        Manfred Vaillancourt
                                    </Text2>
                                    <Text2
                                        className={`lang-${curLang} company-info`}
                                    >
                                        {t(
                                            'privacyPolicy.dataProtectionOfficer.str',
                                        )}
                                    </Text2>

                                    <Text2
                                        className={`lang-${curLang} company-info`}
                                    >
                                        {t(
                                            'privacyPolicy.dataProtectionOfficer.zipCountry',
                                        )}
                                    </Text2>
                                    <Text2
                                        className={`lang-${curLang} company-info`}
                                    >
                                        <ItemLink
                                            className={`lang-${curLang} `}
                                            href="mailto:manfred.vaillancourt@blubina.de"
                                        >
                                            manfred.vaillancourt@blubina.de
                                        </ItemLink>
                                    </Text2>
                                </ContactInfoWrapper>
                            </ContentDetails>
                        </ContentWrapper>

                        {/*---------- Types of data, purposes of processing and categories of data subjects ----------*/}
                        <ContentWrapper>
                            <Subtitle1
                                className={`lang-${curLang} section-title`}
                            >
                                {t('privacyPolicy.dataType.title')}
                            </Subtitle1>
                            <ContentDetails>
                                <Text2
                                    className={`lang-${curLang} section-intro`}
                                >
                                    {t('privacyPolicy.dataType.desc1')}
                                </Text2>

                                <OrderList>
                                    {/* 1 */}
                                    <ListItem>
                                        <Subtitle2
                                            className={`lang-${curLang} list-title`}
                                        >
                                            {t(
                                                'privacyPolicy.dataType.detail1Title',
                                            )}
                                        </Subtitle2>
                                        <Text2
                                            className={`lang-${curLang} list-details`}
                                        >
                                            {t(
                                                'privacyPolicy.dataType.detail1Desc',
                                            )}
                                        </Text2>
                                    </ListItem>
                                    {/* 2 */}
                                    <ListItem>
                                        <Subtitle2
                                            className={`lang-${curLang} list-title`}
                                        >
                                            {t(
                                                'privacyPolicy.dataType.detail2Title',
                                            )}
                                        </Subtitle2>
                                        <Text2
                                            className={`lang-${curLang} list-details`}
                                        >
                                            {t(
                                                'privacyPolicy.dataType.detail2Desc',
                                            )}
                                        </Text2>
                                    </ListItem>
                                    {/* 3 */}
                                    <ListItem>
                                        <Subtitle2
                                            className={`lang-${curLang} list-title`}
                                        >
                                            {t(
                                                'privacyPolicy.dataType.detail3Title',
                                            )}
                                        </Subtitle2>
                                        <Text2
                                            className={`lang-${curLang} list-details`}
                                        >
                                            {t(
                                                'privacyPolicy.dataType.detail3Desc',
                                            )}
                                        </Text2>
                                    </ListItem>
                                </OrderList>
                                <Text2
                                    className={`lang-${curLang} section-intro`}
                                >
                                    {t('privacyPolicy.dataType.desc2')}
                                </Text2>
                            </ContentDetails>
                        </ContentWrapper>

                        {/*---------- Legal basis for the processing of personal data ----------*/}
                        <ContentWrapper>
                            <Subtitle1
                                className={`lang-${curLang} section-title`}
                            >
                                {t('privacyPolicy.legalBasic.title')}
                            </Subtitle1>

                            <ContentDetails>
                                <Text2
                                    className={`lang-${curLang} section-intro`}
                                >
                                    {t('privacyPolicy.legalBasic.desc')}
                                </Text2>

                                <OrderList>
                                    {/* 1 - 5 */}
                                    {privacyPolicy.legalBasic.map(
                                        (item, idx) => (
                                            <ListItem
                                                key={`privacy-policy_legal-basic-${idx + 1}`}
                                            >
                                                <Text2
                                                    className={`lang-${curLang} list-details`}
                                                >
                                                    {t(item)}
                                                </Text2>
                                            </ListItem>
                                        ),
                                    )}
                                </OrderList>
                            </ContentDetails>
                        </ContentWrapper>

                        {/*---------- Disclosure of personal data to third parties and processors ----------*/}
                        <ContentWrapper>
                            <Subtitle1
                                className={`lang-${curLang} section-title`}
                            >
                                {t('privacyPolicy.disclosure.title')}
                            </Subtitle1>
                            <ContentDetails>
                                {privacyPolicy.disclosure.map((item, idx) => (
                                    <Text2
                                        key={`privacy-policy_disclosure-${idx + 1}`}
                                        className={`lang-${curLang} list-details`}
                                    >
                                        {t(item)}
                                    </Text2>
                                ))}
                            </ContentDetails>
                        </ContentWrapper>

                        {/*---------- Data transfer to third countries ----------*/}
                        <ContentWrapper>
                            <Subtitle1
                                className={`lang-${curLang} section-title`}
                            >
                                {t('privacyPolicy.dataTransfer.title')}
                            </Subtitle1>
                            <ContentDetails>
                                {privacyPolicy.dataTransfer.map((item, idx) => (
                                    <Text2
                                        key={`privacy-policy_dataTransfer-${idx + 1}`}
                                        className={`lang-${curLang} list-details`}
                                    >
                                        {t(item)}
                                    </Text2>
                                ))}
                            </ContentDetails>
                        </ContentWrapper>

                        {/*---------- Deletion of data and storage period ----------*/}
                        <ContentWrapper>
                            <Subtitle1
                                className={`lang-${curLang} section-title`}
                            >
                                {t('privacyPolicy.dataDeletion.title')}
                            </Subtitle1>
                            <ContentDetails>
                                <Text2
                                    className={`lang-${curLang} list-details`}
                                >
                                    {t('privacyPolicy.dataDeletion.desc')}
                                </Text2>
                            </ContentDetails>
                        </ContentWrapper>

                        {/*---------- Existence of automated decision making ----------*/}
                        <ContentWrapper>
                            <Subtitle1
                                className={`lang-${curLang} section-title`}
                            >
                                {t('privacyPolicy.existence.title')}
                            </Subtitle1>
                            <ContentDetails>
                                <Text2
                                    className={`lang-${curLang} list-details`}
                                >
                                    {t('privacyPolicy.existence.desc')}
                                </Text2>
                            </ContentDetails>
                        </ContentWrapper>

                        {/*---------- Provision of our website and creation of log files  ----------*/}
                        <ContentWrapper>
                            <Subtitle1
                                className={`lang-${curLang} section-title`}
                            >
                                {t('privacyPolicy.provisionCreation.title')}
                            </Subtitle1>
                            <ContentDetails>
                                <OrderList>
                                    {/* 1 */}
                                    <ListItem>
                                        <Text2
                                            className={`lang-${curLang} list-details`}
                                        >
                                            {t(
                                                'privacyPolicy.provisionCreation.detail1-1',
                                            )}
                                        </Text2>
                                        <UnorderList>
                                            {privacyPolicy.provisionCreation.map(
                                                (item, idx) => (
                                                    <ListItem
                                                        key={`privacy-policy_provision-creation-${idx + 1}`}
                                                    >
                                                        <Text3
                                                            className={`lang-${curLang} list-details-point-em`}
                                                        >
                                                            {t(item)}
                                                        </Text3>
                                                    </ListItem>
                                                ),
                                            )}
                                        </UnorderList>
                                        <Text2>
                                            {t(
                                                'privacyPolicy.provisionCreation.detail1-2',
                                            )}
                                        </Text2>
                                    </ListItem>
                                    {/* 2 */}
                                    <ListItem>
                                        <Text2
                                            className={`lang-${curLang} list-details`}
                                        >
                                            {t(
                                                'privacyPolicy.provisionCreation.detail2',
                                            )}
                                        </Text2>
                                    </ListItem>
                                    {/* 3 */}
                                    <ListItem>
                                        <Text2
                                            className={`lang-${curLang} list-details`}
                                        >
                                            {t(
                                                'privacyPolicy.provisionCreation.detail3',
                                            )}{' '}
                                        </Text2>
                                    </ListItem>
                                    {/* 4 */}
                                    <ListItem>
                                        <Text2
                                            className={`lang-${curLang} list-details`}
                                        >
                                            {t(
                                                'privacyPolicy.provisionCreation.detail4',
                                            )}{' '}
                                        </Text2>
                                    </ListItem>
                                </OrderList>
                            </ContentDetails>
                        </ContentWrapper>

                        {/*---------- Cookies ----------*/}
                        <ContentWrapper>
                            <Subtitle1
                                className={`lang-${curLang} section-title`}
                            >
                                {t('privacyPolicy.cookies.title')}
                            </Subtitle1>
                            <ContentDetails>
                                <OrderList>
                                    {/* 1 */}
                                    <ListItem>
                                        <Text2
                                            className={`lang-${curLang} list-details`}
                                        >
                                            {t(
                                                'privacyPolicy.cookies.detail1Desc1',
                                            )}
                                            <strong>
                                                {t(
                                                    'privacyPolicy.cookies.detail1Desc2',
                                                )}
                                            </strong>
                                        </Text2>
                                        <UnorderList>
                                            <ListItem>
                                                <Text3
                                                    className={`lang-${curLang} list-details-point-em`}
                                                >
                                                    <strong>
                                                        {t(
                                                            'privacyPolicy.cookies.detail1Point1Desc1',
                                                        )}
                                                    </strong>
                                                </Text3>
                                                <Text2
                                                    className={`lang-${curLang} list-details`}
                                                >
                                                    {t(
                                                        'privacyPolicy.cookies.detail1Point1Desc2',
                                                    )}
                                                </Text2>
                                            </ListItem>
                                        </UnorderList>
                                    </ListItem>
                                    {/* 2 */}
                                    <ListItem>
                                        <Text2
                                            className={`lang-${curLang} list-details-title`}
                                        >
                                            {t(
                                                'privacyPolicy.cookies.detail2Title',
                                            )}
                                        </Text2>
                                        <Text2
                                            className={`lang-${curLang} list-details`}
                                        >
                                            {t(
                                                'privacyPolicy.cookies.detail2Desc',
                                            )}
                                        </Text2>
                                    </ListItem>
                                    {/* 3 */}
                                    <ListItem>
                                        <Text2
                                            className={`lang-${curLang} list-details-title`}
                                        >
                                            {t(
                                                'privacyPolicy.cookies.detail3Title',
                                            )}
                                        </Text2>
                                        <Text2
                                            className={`lang-${curLang} list-details`}
                                        >
                                            {t(
                                                'privacyPolicy.cookies.detail3Desc1',
                                            )}
                                        </Text2>
                                        <Text2
                                            className={`lang-${curLang} list-details`}
                                        >
                                            {t(
                                                'privacyPolicy.cookies.detail3Desc2',
                                            )}
                                        </Text2>
                                        <Text2
                                            className={`lang-${curLang} list-details-title`}
                                        >
                                            {t(
                                                'privacyPolicy.cookies.detail3Desc3',
                                            )}
                                        </Text2>
                                        <UnorderList>
                                            <ListItem>
                                                <Text3
                                                    className={`lang-${curLang} list-details-point-em`}
                                                >
                                                    Chrome:
                                                    <ItemLink
                                                        className={`lang-${curLang}`}
                                                        href={t(
                                                            'privacyPolicy.cookies.detail3Chrome',
                                                        )}
                                                        target="_blank"
                                                    >
                                                        {t(
                                                            'privacyPolicy.cookies.detail3Chrome',
                                                        )}
                                                    </ItemLink>
                                                </Text3>
                                            </ListItem>
                                            <ListItem>
                                                <Text3
                                                    className={`lang-${curLang} list-details-point-em`}
                                                >
                                                    Safari:
                                                    <ItemLink
                                                        className={`lang-${curLang}`}
                                                        href={t(
                                                            'privacyPolicy.cookies.detail3Safari',
                                                        )}
                                                        target="_blank"
                                                    >
                                                        {t(
                                                            'privacyPolicy.cookies.detail3Safari',
                                                        )}
                                                    </ItemLink>
                                                </Text3>
                                            </ListItem>
                                            <ListItem>
                                                <Text3
                                                    className={`lang-${curLang} list-details-point-em`}
                                                >
                                                    Firefox:
                                                    <ItemLink
                                                        className={`lang-${curLang}`}
                                                        href={t(
                                                            'privacyPolicy.cookies.detail3Firefox',
                                                        )}
                                                        target="_blank"
                                                    >
                                                        {t(
                                                            'privacyPolicy.cookies.detail3Firefox',
                                                        )}
                                                    </ItemLink>
                                                </Text3>
                                            </ListItem>
                                            <ListItem>
                                                <Text3
                                                    className={`lang-${curLang} list-details-point-em`}
                                                >
                                                    Internet Explorer:
                                                    <ItemLink
                                                        className={`lang-${curLang}`}
                                                        href={t(
                                                            'privacyPolicy.cookies.detail3IE',
                                                        )}
                                                        target="_blank"
                                                    >
                                                        {t(
                                                            'privacyPolicy.cookies.detail3IE',
                                                        )}
                                                    </ItemLink>
                                                </Text3>
                                            </ListItem>
                                            <ListItem>
                                                <Text3
                                                    className={`lang-${curLang} list-details-point-em`}
                                                >
                                                    Microsoft Edge:
                                                    <ItemLink
                                                        className={`lang-${curLang}`}
                                                        href={t(
                                                            'privacyPolicy.cookies.detail3ME',
                                                        )}
                                                        target="_blank"
                                                    >
                                                        {t(
                                                            'privacyPolicy.cookies.detail3ME',
                                                        )}
                                                    </ItemLink>
                                                </Text3>
                                            </ListItem>
                                        </UnorderList>
                                    </ListItem>
                                    {/* 4 */}
                                    <ListItem>
                                        <Text2
                                            className={`lang-${curLang} list-details-title`}
                                        >
                                            {t(
                                                'privacyPolicy.cookies.detail4Title',
                                            )}
                                        </Text2>
                                        <Text2
                                            className={`lang-${curLang} list-details`}
                                        >
                                            {t(
                                                'privacyPolicy.cookies.detail4Desc',
                                            )}
                                        </Text2>
                                    </ListItem>
                                </OrderList>
                            </ContentDetails>
                        </ContentWrapper>

                        {/*---------- Settlement of contracts ----------*/}
                        <ContentWrapper>
                            <Subtitle1
                                className={`lang-${curLang} section-title`}
                            >
                                {t('privacyPolicy.settlementContracts.title')}
                            </Subtitle1>
                            <ContentDetails>
                                <OrderList>
                                    {/* 1 - 4 */}
                                    {privacyPolicy.settlementContracts.map(
                                        (item, idx) => (
                                            <ListItem
                                                key={`privacy-policy_settlement-contracts-${idx + 1}`}
                                            >
                                                <Text2
                                                    className={`lang-${curLang} list-details`}
                                                >
                                                    {t(item)}
                                                </Text2>
                                            </ListItem>
                                        ),
                                    )}
                                </OrderList>
                            </ContentDetails>
                        </ContentWrapper>

                        {/*---------- Social media presence ----------*/}
                        <ContentWrapper>
                            <Subtitle1
                                className={`lang-${curLang} section-title`}
                            >
                                {t('privacyPolicy.socialMedia.title')}
                            </Subtitle1>
                            <ContentDetails>
                                <OrderList>
                                    {/* 1 */}
                                    <ListItem>
                                        <Text2
                                            className={`lang-${curLang} list-details`}
                                        >
                                            {t(
                                                'privacyPolicy.socialMedia.detail1Desc',
                                            )}
                                        </Text2>
                                    </ListItem>
                                    {/* 2 */}
                                    <ListItem>
                                        <Text2
                                            className={`lang-${curLang} list-details-title`}
                                        >
                                            {t(
                                                'privacyPolicy.socialMedia.detail2Title',
                                            )}
                                        </Text2>
                                        <Text2
                                            className={`lang-${curLang} list-details`}
                                        >
                                            {t(
                                                'privacyPolicy.socialMedia.detail2Desc',
                                            )}
                                        </Text2>
                                    </ListItem>
                                    {/* 3 */}
                                    <ListItem>
                                        <Text2
                                            className={`lang-${curLang} list-details-title`}
                                        >
                                            {t(
                                                'privacyPolicy.socialMedia.detail3Title',
                                            )}
                                        </Text2>
                                        <Text2
                                            className={`lang-${curLang} list-details`}
                                        >
                                            {t(
                                                'privacyPolicy.socialMedia.detail3Desc',
                                            )}
                                        </Text2>
                                    </ListItem>
                                    {/* 4 */}
                                    <ListItem>
                                        <Text2
                                            className={`lang-${curLang} list-details-title`}
                                        >
                                            {t(
                                                'privacyPolicy.socialMedia.detail4Title',
                                            )}
                                        </Text2>
                                        <Text2
                                            className={`lang-${curLang} list-details`}
                                        >
                                            {t(
                                                'privacyPolicy.socialMedia.detail4Desc',
                                            )}
                                        </Text2>
                                    </ListItem>
                                    {/* 5 */}
                                    <ListItem>
                                        <Text2
                                            className={`lang-${curLang} list-details-title`}
                                        >
                                            {t(
                                                'privacyPolicy.socialMedia.detail5Title',
                                            )}
                                        </Text2>
                                        <Text2
                                            className={`lang-${curLang} list-details`}
                                        >
                                            {t(
                                                'privacyPolicy.socialMedia.detail5Desc',
                                            )}
                                        </Text2>
                                    </ListItem>
                                    {/* 6 */}
                                    <ListItem>
                                        <Text2
                                            className={`lang-${curLang} list-details`}
                                        >
                                            {t(
                                                'privacyPolicy.socialMedia.detail6Desc',
                                            )}
                                        </Text2>
                                        <UnorderList>
                                            <ListItem>
                                                <Text3
                                                    className={`lang-${curLang} list-details_point`}
                                                >
                                                    LinkedIn
                                                    {t(
                                                        'privacyPolicy.socialMedia.detail6Point1',
                                                    )}
                                                </Text3>
                                            </ListItem>
                                            <ListItem>
                                                <Text3
                                                    className={`lang-${curLang} list-details_point`}
                                                >
                                                    {t(
                                                        'privacyPolicy.socialMedia.detail6Point2',
                                                    )}
                                                    <ItemLink
                                                        className={`lang-${curLang}`}
                                                        href="https://www.linkedin.com/legal/privacy-policy"
                                                        target="_blank"
                                                    >
                                                        https://www.linkedin.com/legal/privacy-policy
                                                    </ItemLink>
                                                </Text3>
                                            </ListItem>
                                            <ListItem>
                                                <Text3
                                                    className={`lang-${curLang} list-details_point`}
                                                >
                                                    {t(
                                                        'privacyPolicy.socialMedia.detail6Point3',
                                                    )}
                                                    <ItemLink
                                                        className={`lang-${curLang}`}
                                                        href="https://www.linkedin.com/legal/cookie-policy"
                                                        target="_blank"
                                                    >
                                                        https://www.linkedin.com/legal/cookie-policy
                                                    </ItemLink>
                                                </Text3>
                                            </ListItem>
                                        </UnorderList>
                                    </ListItem>
                                </OrderList>
                            </ContentDetails>
                        </ContentWrapper>

                        {/*---------- Data protection in applications and the application process ----------*/}
                        <ContentWrapper>
                            <Subtitle1
                                className={`lang-${curLang} section-title`}
                            >
                                {t('privacyPolicy.dataProtection.title')}
                            </Subtitle1>
                            <ContentDetails>
                                <OrderList>
                                    {/* 1-4 */}
                                    {privacyPolicy.dataProtection.map(
                                        (item, idx) => (
                                            <ListItem
                                                key={`privacy-policy_data-protection-${idx}`}
                                            >
                                                <Text2
                                                    className={`lang-${curLang} list-details`}
                                                >
                                                    {t(item)}
                                                </Text2>
                                            </ListItem>
                                        ),
                                    )}
                                </OrderList>
                            </ContentDetails>
                        </ContentWrapper>

                        {/*---------- Rights of the data subject ----------*/}
                        <ContentWrapper>
                            <Subtitle1
                                className={`lang-${curLang} section-title`}
                            >
                                {t('privacyPolicy.dataRights.title')}
                            </Subtitle1>
                            <ContentDetails>
                                <OrderList>
                                    {/* 1 */}
                                    <ListItem>
                                        <Text2
                                            className={`lang-${curLang} list-details-title`}
                                        >
                                            {t(
                                                'privacyPolicy.dataRights.detail1Title',
                                            )}
                                        </Text2>
                                        <Text2
                                            className={`lang-${curLang} list-details`}
                                        >
                                            {t(
                                                'privacyPolicy.dataRights.detail1Desc1',
                                            )}
                                        </Text2>
                                        <Text2
                                            className={`lang-${curLang} list-details`}
                                        >
                                            {t(
                                                'privacyPolicy.dataRights.detail1Desc2',
                                            )}
                                        </Text2>
                                        <Text2
                                            className={`lang-${curLang} list-details`}
                                        >
                                            {t(
                                                'privacyPolicy.dataRights.detail1Desc3',
                                            )}
                                        </Text2>
                                        <ContactInfoWrapper>
                                            <Text2
                                                className={`lang-${curLang} company-name`}
                                            >
                                                Blubina GmbH
                                            </Text2>
                                            <Text3
                                                className={`lang-${curLang} company-info`}
                                            >
                                                {t(
                                                    'privacyPolicy.nameContact.companyStr',
                                                )}
                                            </Text3>

                                            <Text3
                                                className={`lang-${curLang} company-info`}
                                            >
                                                {t(
                                                    'privacyPolicy.nameContact.companyZipCountry',
                                                )}
                                            </Text3>
                                            <Text3
                                                className={`lang-${curLang} company-info`}
                                            >
                                                {t(
                                                    'privacyPolicy.nameContact.companyDirector',
                                                )}
                                            </Text3>
                                            <Text3
                                                className={`lang-${curLang} company-info`}
                                            >
                                                {t(
                                                    'privacyPolicy.nameContact.companyCommercialRegister',
                                                )}
                                            </Text3>
                                            <Text3
                                                className={`lang-${curLang} company-info`}
                                            >
                                                {t(
                                                    'privacyPolicy.nameContact.companyRegisterCourt',
                                                )}
                                            </Text3>
                                            <Text3
                                                className={`lang-${curLang} company-info`}
                                            >
                                                {t(
                                                    'privacyPolicy.nameContact.companyEmailAddress',
                                                )}
                                                <ItemLink
                                                    className={`lang-${curLang}`}
                                                    href={`mailto:${contactLink}`}
                                                >
                                                    {contactLink}
                                                </ItemLink>
                                            </Text3>
                                        </ContactInfoWrapper>
                                    </ListItem>
                                    {/* 2 */}
                                    <ListItem>
                                        <Text2
                                            className={`lang-${curLang} list-details-title`}
                                        >
                                            {t(
                                                'privacyPolicy.dataRights.detail2Title',
                                            )}
                                        </Text2>
                                        <Text2
                                            className={`lang-${curLang} list-details`}
                                        >
                                            {t(
                                                'privacyPolicy.dataRights.detail2Desc',
                                            )}
                                        </Text2>
                                    </ListItem>
                                    {/* 3 */}
                                    <ListItem>
                                        <Text2
                                            className={`lang-${curLang} list-details-title`}
                                        >
                                            {t(
                                                'privacyPolicy.dataRights.detail3Title',
                                            )}
                                        </Text2>
                                        <Text2
                                            className={`lang-${curLang} list-details`}
                                        >
                                            {t(
                                                'privacyPolicy.dataRights.detail3Desc',
                                            )}
                                        </Text2>
                                    </ListItem>
                                    {/* 4 */}
                                    <ListItem>
                                        <Text2
                                            className={`lang-${curLang} list-details-title`}
                                        >
                                            {t(
                                                'privacyPolicy.dataRights.detail4Title',
                                            )}
                                        </Text2>
                                        <Text2
                                            className={`lang-${curLang} list-details`}
                                        >
                                            {t(
                                                'privacyPolicy.dataRights.detail4Desc',
                                            )}
                                        </Text2>
                                    </ListItem>
                                    {/* 5 */}
                                    <ListItem>
                                        <Text2
                                            className={`lang-${curLang} list-details-title`}
                                        >
                                            {t(
                                                'privacyPolicy.dataRights.detail5Title',
                                            )}
                                        </Text2>
                                        <Text2
                                            className={`lang-${curLang} list-details`}
                                        >
                                            {t(
                                                'privacyPolicy.dataRights.detail5Desc',
                                            )}
                                        </Text2>
                                        <UnorderList>
                                            <ListItem>
                                                <Text3
                                                    className={`lang-${curLang} list-details-point`}
                                                >
                                                    {t(
                                                        'privacyPolicy.dataRights.detail5Point1',
                                                    )}
                                                </Text3>
                                            </ListItem>
                                            <ListItem>
                                                <Text3
                                                    className={`lang-${curLang} list-details-point`}
                                                >
                                                    {t(
                                                        'privacyPolicy.dataRights.detail5Point2',
                                                    )}
                                                </Text3>
                                            </ListItem>
                                            <ListItem>
                                                <Text3
                                                    className={`lang-${curLang} list-details-point`}
                                                >
                                                    {t(
                                                        'privacyPolicy.dataRights.detail5Point3',
                                                    )}
                                                </Text3>
                                            </ListItem>
                                            <ListItem>
                                                <Text3
                                                    className={`lang-${curLang} list-details-point`}
                                                >
                                                    {t(
                                                        'privacyPolicy.dataRights.detail5Point4',
                                                    )}
                                                </Text3>
                                            </ListItem>
                                        </UnorderList>
                                    </ListItem>
                                    {/* 6 */}
                                    <ListItem>
                                        <Text2
                                            className={`lang-${curLang} list-details-title`}
                                        >
                                            {t(
                                                'privacyPolicy.dataRights.detail6Title',
                                            )}
                                        </Text2>
                                        <Text2
                                            className={`lang-${curLang} list-details`}
                                        >
                                            {t(
                                                'privacyPolicy.dataRights.detail6Desc',
                                            )}
                                        </Text2>
                                    </ListItem>
                                    {/* 7 */}
                                    <ListItem>
                                        <Text2
                                            className={`lang-${curLang} list-details-title`}
                                        >
                                            {t(
                                                'privacyPolicy.dataRights.detail7Title',
                                            )}
                                        </Text2>
                                        <Text2
                                            className={`lang-${curLang} list-details`}
                                        >
                                            {t(
                                                'privacyPolicy.dataRights.detail7Desc',
                                            )}
                                        </Text2>
                                    </ListItem>
                                </OrderList>
                            </ContentDetails>
                        </ContentWrapper>

                        {/*---------- Data security ----------*/}
                        <ContentWrapper>
                            <Subtitle1
                                className={`lang-${curLang} section-title`}
                            >
                                {t('privacyPolicy.dataSecurity.title')}
                            </Subtitle1>
                            <ContentDetails>
                                <Text2
                                    className={`lang-${curLang} list-details`}
                                >
                                    {t('privacyPolicy.dataSecurity.desc')}
                                </Text2>
                            </ContentDetails>
                        </ContentWrapper>

                        {/*---------- Source ----------*/}
                        <ContentWrapper>
                            <ContentDetails>
                                <Text2
                                    className={`lang-${curLang} list-details`}
                                >
                                    {t('privacyPolicy.main.source')}
                                    <ItemLink
                                        className={`lang-${curLang}`}
                                        href="https://www.juraforum.de/datenschutzerklaerung-muster"
                                        target="_blank"
                                    >
                                        {t('common.btn.moreHere')}
                                    </ItemLink>
                                </Text2>
                            </ContentDetails>
                        </ContentWrapper>
                    </Section>
                </Inner>
                <Footer curLang={curLang} />
            </Wrapper>
        </>
    );
};

export default PrivacyPolicyPage;
