import React from 'react';
import styled from 'styled-components';
import Video from './Video';

const Wrapper = styled.div``;

interface Video {
    id: string;
    idx: number;
    videoSrc: any;
}

interface Props {
    activeIdx: number;
    vidArr: Video[];
}

const VideoSlides: React.FC<Props> = ({ activeIdx, vidArr }) => {
    return (
        <Wrapper>
            {vidArr?.map(({ id, idx, videoSrc }) => (
                <Video
                    key={id}
                    videoSrc={videoSrc}
                    isAutoPlay={true}
                    status={activeIdx === idx ? 'active' : 'inactive'}
                />
            ))}
        </Wrapper>
    );
};

export default VideoSlides;
