import styled from 'styled-components';
import { tooltipIndex } from '../utils/common';

export const Tooltip = styled.div`
    font-family: 'Noto Sans', 'Roboto', sans-serif;
    visibility: hidden;
    opacity: 0;
    /* visibility: visible;
    opacity: 1; */
    position: absolute;
    top: 4rem;
    left: 1rem;
    width: auto;
    height: auto;
    z-index: ${tooltipIndex};
    background: var(--tooltip-bg);
    color: var(--tooltip-txt);
    border-radius: var(--toolip-bd-r);
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: 0.3px;
    padding: 0.3rem 0.8rem 0.4rem;
    /* word-break: keep-all; */
    /* word-break: break-all; */
    /* white-space: break-spaces; */
    word-break: break-word;
    text-align: center;
    transition: opacity 0.2s ease;
    /* box-shadow: 1px 1px 5px -5px rgba(0, 0, 0, 0.1); */
`;

export const BtnTooltip = styled(Tooltip)`
    /* visibility: visible; */
    /* opacity: 1; */
    top: 3.6rem;
    left: 50%;
    z-index: ${tooltipIndex};
    transform: translateX(-40%);
    word-break: keep-all;
    white-space: nowrap;
    text-align: center;
    transition: opacity 0.2s ease 0.5s;

    &.top {
        top: -3.2rem;
    }
`;
