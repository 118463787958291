import React from 'react';
import styled from 'styled-components';
import { tabletViews } from '../utils/media';

const Wrapper = styled.div`
    position: relative;
    display: block;

    &.active {
        display: block;
    }

    &.inactive {
        display: none;
    }
`;

const Inner = styled.div`
    position: relative;
    max-width: 120rem;
    width: 100%;
    margin: 0 auto;

    video {
        border-radius: var(--vid-bd-r);
    }

    /* max-width: 1536px */
    @media (max-width: 1536px) {
        max-width: 120rem;
    }

    /* max-width: 992px */
    @media ${tabletViews.xlg} {
        max-width: 100%;
        min-width: 100%;
    }
`;

interface Props {
    videoSrc: any;
    videoDesc?: null | string;
    isAutoPlay?: boolean;
    status?: string;
}

const Video: React.FC<Props> = ({ videoSrc, isAutoPlay, status }) => {
    return (
        <Wrapper className={status}>
            <Inner>
                <video
                    preload="auto"
                    controls={true}
                    loop={true}
                    muted={true}
                    autoPlay={isAutoPlay}
                    controlsList="nodownload"
                >
                    <source src={videoSrc} type="video/mp4"></source>
                </video>
            </Inner>
        </Wrapper>
    );
};

export default Video;
